import React from 'react';
import styled from 'styled-components';


const ThemePreviewContainer = styled.div`
  box-sizing: border-box;
  padding: 0;
  font-size: 1rem;
  font-weight: bold;
  display: flex !important;
  flex-direction: column !important;
  align-items: stretch !important;
  cursor: pointer;
  pointer-events: all;
  border-radius: 0.2rem;
  box-shadow: 0px 3px 8px #00000088;
  height: min-content;
  outline: 0.5rem solid transparent;
  outline-color: ${props => (props.selected ? `green` : `transparent`)};
  /* transform: scale(0.9); */
  margin: 1vmax 0;
  min-width: max-content;

  & .font-ids {
    font-family: sans-serif !important;
    font-size: 0.65rem;
    text-align: left;
  }

  & > header {
    font-size: 1.25rem !important;
    min-height: var(--footer-height);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    padding: 0.5rem;
  }
  & header > div {
  }
  & header > small {
    font-size: 0.8rem;
    font-weight: normal;
    padding: 0.25rem;
  }
  @media screen and (orientation: landscape) {
    margin: 1vmax;
  }
`;

const SectionArea = styled.div`
  border-radius: inherit;
  display: flex;
  flex-direction: column;
  /* height: 100%; */
  `;
const MiniSection = styled.div`
  flex-basis: var(--header-height);
  /* flex-grow: 1; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: inherit;
  border: 0.1rem solid ${props => props.headerColor};
`;

export default function ThemePreview(props) {
  return (
    <ThemePreviewContainer
      onClick={() => props.onSelectTheme(props.index)}
      theme={props.theme}
      selected={props.selected}
      style={{
        fontFamily: props.theme.contents.globalStyles.mainFont.fontFamily,
        backgroundColor: props.theme.contents.globalStyles.headerColor,
        color: props.theme.contents.globalStyles.blackLogo ? '#101010' : '#dfdfdf'
      }}
    >
      <header>
        <div
          style={{
            fontFamily: props.theme.contents.globalStyles.titleFont.fontFamily
          }}
        >
          {props.theme.name}
        </div>
        <small>by {props.theme.creator}</small>
        <small className='font-ids'>
          <div>Title: {props.theme.contents.globalStyles.titleFont.fontFamily}</div>
          <div>Main: {props.theme.contents.globalStyles.mainFont.fontFamily}</div>
        </small>
      </header>
      <SectionArea>
        {props.theme.contents.sections.slice(1, props.theme.contents.sections.length).map((sec, s) => (
          <MiniSection key={s} style={sec.style} headerColor={props.theme.contents.globalStyles.headerColor}>
            <div
              style={{ fontFamily: props.theme.contents.globalStyles.mainFont.fontFamily, fontSize: props.theme.contents.globalStyles.mainFont.fontSize }}
              className='preview-text'
            >{`Some text`}</div>
          </MiniSection>
        ))}
      </SectionArea>
    </ThemePreviewContainer>
  );
}
