import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

let isWindow = window.location.search.length && (window.location.search.indexOf('username') > -1 || window.location.href.indexOf('username') > -1);
  
ReactDOM.render(<App isWindow={isWindow}/>, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
