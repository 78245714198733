import React, { useEffect, useState, useRef } from 'react';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import ThemePreview from '../ThemePreview.jsx';
import { getColorFilters } from '../scripts/colorfilter.js';

const StyleCard = styled(Card)`
  position: fixed;
  min-width: 70vw;
  max-width: 90vw;
  height: 90vh;
  transform: translate(-50%, 0);
  top: 5vh;
  left: 50%;
  padding: 1%;
  font-size: 0.8rem;
  font-weight: bold;
  display: flex !important;
  flex-direction: column !important;
  align-items: center;
  justify-content: space-between;
  background-color: #cccccc !important;
  z-index: 2;

  & > header {
    font-size: 1.5rem;
    min-height: calc(var(--header-height) * 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & > footer.button-area {
    height: calc(var(--header-height) * 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: flex-end;
    padding: calc(var(--header-height) * 0.1) 0;
    background: inherit;
  }
  & > footer.button-area:after,
  & > header:after {
    position: absolute;
    bottom: calc(var(--header-height) * 0.7);
    left: 0;
    width: 100%;
    height: var(--header-height);
    content: '';
    background-image: url('https://cms.eggborne.com/wagsworth/assets/fadeedgebottom.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    z-index: 2;
    ${props => props.bgTint}
  }
  & > header:after {
    position: absolute;
    transform: scaleY(-1);
    top: calc(var(--header-height) * 0.6);
  }
  & > .button-area button {
    background: var(--mustard-yellow);
    height: calc(var(--header-height) * 0.75);
    padding: unset;
    margin: 0 !important;
    width: 100%;
    z-index: 4;
  }
  & button:last-of-type {
    margin-left: 2vmin !important;
    background: green;
    color: white;
  }
  & button:disabled {
    color: #aaa;
  }
  @media screen and (orientation: landscape) {
    /* min-width: max-content; */
    /* max-width: unset; */
    padding: 1%;
  }
`;

const ThemeList = styled.div`
  scroll-behavior: smooth;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  align-self: center;
  justify-content: center;
  padding: calc(var(--header-height) / 2.25) 0;
  width: 100%;

  & > div {
    min-width: 40%;
    max-width: 42%;
    /* transform: scale(0.9); */
  }
  & > div:not(:last-child) {
    /* margin-right: 0.1vw; */
  }

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  @media screen and (orientation: landscape) {
    /* justify-content: flex-start; */
    & > div {
      min-width: 20%;
      /* transform: scale(0.9); */
    }
  }
`;

const Arrow = styled.div`
  align-self: center;
  width: 0;
  height: 0;
  border-left: 1.5rem solid rgba(255, 255, 255, 0);
  border-right: 1.5rem solid rgba(255, 255, 255, 0);
  border-top: 2rem solid #eee;
  filter: drop-shadow(0 0 2px #000000);
  margin: 1rem;
  z-index: 2;
`;
const LeftArrow = styled(Arrow)`
  lefT: 0;
  transform: rotate(90deg);
`;
const RightArrow = styled(Arrow)`
  transform: rotate(-90deg);
  right: 0;
`;

const scrollAmount = window.innerWidth / 2;

export default function LoadThemeModal(props) {
  const [selectedTheme, setSelectedTheme] = useState(null);
  const [bgTint, setBgTint] = useState('');
  const themeListRef = useRef();
  let cardClass = props.showing ? 'Card bouncing' : 'Card';

  useEffect(() => {
    let filters = getColorFilters('#cccccc').filter;
    console.log('set fil to', filters)
    setBgTint(filters);
  }, []);

  const handleSelectTheme = (newThemeIndex) => {
    console.log('theme is now', newThemeIndex)
    setSelectedTheme(newThemeIndex);
  }
  const handleLoadTheme = () => {
    console.log('theme loading:', selectedTheme)
    props.closeModal();
    props.loadTheme(selectedTheme);
  }
  const scrollLeft = () => {
    themeListRef.current.scrollBy(-scrollAmount, 0);
  }
  const scrollRight = () => {
    themeListRef.current.scrollBy(scrollAmount, 0);  
  }
  
  return (
    <StyleCard id='load-theme-card' bgTint={bgTint} className={cardClass}>
      <header>{'Themes'}</header>
      {props.themes.length ? (
        <ThemeList id='theme-list' ref={themeListRef}>
          {props.themes.map((theme, t) => {
            let headerColor = theme.contents.sections[0].style.backgroundColor;
            return (
              <ThemePreview key={t} includeFonts={true} index={t} onSelectTheme={handleSelectTheme} selected={selectedTheme === t} theme={theme} headerColor={headerColor} />
            );
          })}
        </ThemeList>
      ) : (
        <div>loading...</div>
      )}
      <footer className='button-area'>
        <Button className={`Button`} onClick={props.closeModal} color='primary' variant='contained'>
          Cancel
        </Button>
        <Button disabled={selectedTheme === null} className={`Button`} onClick={handleLoadTheme} variant='contained'>
          Load
        </Button>
      </footer>
    </StyleCard>
  );
}
