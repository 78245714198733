import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { useState, useEffect } from 'react';

let screenRatio = window.innerHeight / window.innerWidth;

let dropzoneTemplate = `
  <div class="dz-preview dz-file-preview">
    <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
  </div>
`;

const BackgroundPreviewContainer = styled.div`
  position: relative;  
  min-width: calc(var(--header-height) * 2 * (16 / 9));
  height: calc(var(--header-height) * 2);
  min-height: calc(var(--header-height) * 2);
  /* grid-row-end: span 2; */
  outline: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: ${props => props.tint};
  cursor: pointer;
  
  &#portrait-background-preview {
    min-width: unset;
    /* width: calc((var(--header-height) * 2));
    min-height: calc(var(--header-height) * 2 * (16 / 9)); */
    /* min-height: 100%; */
    width: 100%;
    justify-self: center;
    cursor: unset;
    /* background-repeat: unset !important;
    border-style: solid;
    border-width: calc(var(--header-height) / 2) calc(var(--header-height) / 8);
    border-color: white;
    border-image-source: url('https://cms.eggborne.com/galaxys.png');
    border-image-slice: 10% 20%;
    border-image-width:  calc(var(--header-height) / 2) calc(var(--header-height) / 6); */
  }

  & > #background-preview-image,
  & > #portrait-background-preview-image {
    width: 100%;
    height: 100%;
    /* height: auto; */
    opacity: ${props => props.opacity};
    pointer-events: none;
    overflow: hidden;
    background-size: ${props => (props.stretchBackground ? `100% 100%` : props.fillDimension === 'width' ? `${props.backgroundImageSize}% auto` : `auto ${props.backgroundImageSize}%`)};

  }

  &#background-preview::after {
    font-size: 0.8em;
    font-weight: normal;
    pointer-events: none;
    position: absolute;
    transform: translate(0%, -50%);
    top: 50%;
    left: 0;
    width: 100%;
    color: #ffffff77;
    content: 'Tap here to change';
  }

  @media (orientation: landscape) {
    position: relative;
    --preview-width: calc(var(--header-height) * 4);
    width: var(--preview-width);
    height: calc(var(--preview-width) * ${screenRatio});
    min-height: calc(var(--preview-width) * ${screenRatio});
    /* max-height: calc(var(--preview-width) * ${screenRatio}); */

    &#portrait-background-preview {
      width: calc(var(--header-height) * 2);
      min-height: calc((var(--header-height) * 2) * (16 / 9));
      grid-row-end: span 2;
    }

    & > #background-preview-image {
      /* height: ${props => props.backgroundImageSize}% !important;
      width: auto !important; */
      /* width: ${props => props.backgroundImageSize}% !important;
      height: auto !important; */
    }
    &#background-preview::after {
      font-size: 0.8em;
      font-weight: normal;
      pointer-events: none;
      position: absolute;
      transform: translate(0%, -50%);
      top: 50%;
      left: 0;
      width: 100%;
      color: #ffffff77;
      /* height: 100%; */
      content: 'Click or drop image here to change';
    }
  }
`;

function BackgroundPreview(props) {
  const [fillDimension, setFillDimension] = useState('');

  useEffect(() => {
    if (!props.portrait) {
      const extraParams = {
        username: props.username,
        token: props.token
      };
      console.log('BackgroundPreview useEffect [] sending extraparams', extraParams);
      window.backgroundImageDropzone = new window.Dropzone('div#background-preview', {
        url: 'https://cms.eggborne.com/php/cmsfileupload2.php',
        paramName: 'gallery-image', // The name that will be used to transfer the file
        params: extraParams,
        maxFilesize: 10, // MB
        headers: {
          'Cache-Control': null,
          'X-Requested-With': null,
        },
        accept: (file, done) => {
          // props.updateBackgroundPreview(file);
          done();
        },
        previewTemplate: dropzoneTemplate
      });
      window.backgroundImageDropzone.on('complete', function(file) {
        props.updateBackgroundPreview(file);
        window.backgroundImageDropzone.removeFile(file);
      });
    }
  }, []);
  useEffect(() => {
    const previewEl = document.getElementById(`${props.portrait ? `portrait-` : ``}background-preview-image`);
    const imageEl = new Image();
    imageEl.src = props.backgroundImageUrl;
    imageEl.style.visibility = 'hidden';
    document.body.append(imageEl)
    imageEl.addEventListener('load', () => {
      // imageEl.style.width = previewEl.offsetWidth + 'px';
      let rect = imageEl.getBoundingClientRect();
      const imageAspectRatio = rect.width / rect.height;
      const prevAspectRatio = previewEl.offsetWidth / previewEl.offsetHeight;
      let fill;
      if (props.portrait) {
        // phone preview
        if (imageAspectRatio > prevAspectRatio) {
          // image is taller
          fill = 'height';
        } else {
          fill = 'width';        
        }
      } else {
        //desktop preview
        if (imageAspectRatio > prevAspectRatio) {
          // image is taller
          fill = 'height';
        } else {
          fill = 'width';        
        }
      }
      // if (xDifference > yDifference) {
      //   console.error(props.portrait && 'portrait ', 'fill xDiff > yDiff');
      //   if (oblong) {
      //     fill = props.portrait ? 'height' : 'width';
      //   } else {
      //     fill = props.portrait ? 'width' : 'height';
      //   }
      //   setFillDimension(fill);
      //   console.error('fill img size', imageEl.offsetWidth, imageEl.offsetHeight)
      //   console.error('fill prev size', previewEl.offsetWidth, previewEl.offsetHeight)
      //   console.error('fill rect', rect.width, rect.height)
      //   console.error('fill xdiff', xDifference, 'ydiff', yDifference)
      //   console.error('fill chosen:', fill)
      // } else {
      //   console.error(props.portrait && 'portrait ', 'fill xDiff < yDiff');
      //   if (oblong) {
      //     if (props.portrait) {
      //       fill = 'height';
      //     } else {
      //       fill = 'width';
      //     }
      //     fill = props.portrait ? 'height' : 'width';
      //   } else {
      //     fill = props.portrait ? 'width' : 'height';
      //   }
      //   setFillDimension(fill);
      //   console.error('fill img size', imageEl.offsetWidth, imageEl.offsetHeight)
      //   console.error('fill prev size', previewEl.offsetWidth, previewEl.offsetHeight)
      //   console.error('fill rect', rect.width, rect.height)
      //   console.error('fill xdiff', xDifference, 'ydiff', yDifference)
      //   console.error('fill chosen:', fill)
      // }
      setFillDimension(fill);
      document.body.removeChild(imageEl);
    });
  }, [props.backgroundImageUrl]);
  return (
    <BackgroundPreviewContainer
      fillDimension={fillDimension}
      id={`${props.portrait ? `portrait-` : ``}background-preview`}
      tint={props.tint}
      opacity={props.opacity}
      backgroundImageUrl={props.backgroundImageUrl}
      backgroundImageSize={props.backgroundImageSize}
      stretchBackground={props.stretchBackground}
    >
      <div
        style={{
          backgroundImage: `url('${props.backgroundImageUrl}')`,
          // backgroundSize: fillDimension === 'width' ? `auto ${props.backgroundImageSize}%` : `${props.backgroundImageSize}% auto`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        }}
        alt='Background preview'
        id={`${props.portrait ? `portrait-` : ``}background-preview-image`}
      ></div>
    </BackgroundPreviewContainer>
  );
}

export default BackgroundPreview;
