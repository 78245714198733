import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';

const ImageUploadScreenContainer = styled.div`
  position: fixed;
  overflow: hidden;
  pointer-events: all;
  width: 75vw;
  height: calc(var(--screen-height) - var(--header-height));
  transform: translate(-50%, -50%);
  left: 50vw;
  top: 50vh;
  z-index: 3;
  border: 0;
  box-shadow: -4px -4px 24px 4px #00000088;
  color: #ddd;
  font-size: 1.5rem;
  display: grid;
  grid-template-rows: auto 1fr;
  align-items: center;
  justify-items: center;
  background-color: #9e9eb4;
  border-radius: 1rem;

  &#image-upload-screen > header {
    font-weight: bold;
    align-self: stretch;
    justify-self: stretch;
    display: flex;
    border-radius: 1rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 0.5rem;
  }
  &#image-upload-screen > button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    min-width: unset;
    width: calc(var(--header-height) / 1.35);
    height: calc(var(--header-height) / 1.35);
    border-radius: 0.5rem;
    background-color: red;
    font-family: monospace;
    font-weight: bold;
    font-size: calc(var(--header-height) / 2.5);
  }
  &#image-upload-screen & > button:hover {
    background-color: #a00;
  }
`;
function ImageUploadScreen(props) {
  // const closeSelf = (e) => {
  //   let iframe = document.getElementById('image-upload-frame').classList.add('inactive');
  //   console.log('oframe is??', iframe)
  //   iframe.parentNode.removeChild(iframe);
  // }
  return (
    <ImageUploadScreenContainer id={'image-upload-screen'}>
      <header>File upload</header>
      <iframe id={'image-upload-frame'} title={'image-upload-frame'} src={`http://localhost:3001/?username=${props.userData.username}&token=${props.userData.token}`} />
      <Button variant='contained' onClick={props.closeModal}>
        X
      </Button>
    </ImageUploadScreenContainer>
  );
}

export default ImageUploadScreen;
