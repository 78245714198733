import React, { useState, useEffect, useCallback } from 'react';
import ImageUploadScreen from './ImageUploadScreen.jsx';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import Slider from '@material-ui/core/Slider';
import axios from 'axios';
import LoginFields from './LoginFields.jsx';
import Header from './Header.jsx';
import styled from 'styled-components';
import Panel from './Panel.jsx';
import ChangePasswordModal from './modals/ChangePasswordModal.jsx';
import AdjustStyleModal from './modals/AdjustStyleModal.jsx';
import LoadThemeModal from './modals/LoadThemeModal.jsx';
import SaveButton from './SaveButton.jsx';
import SaveIndicator from './SaveIndicator.jsx';
import BackgroundPreview from './BackgroundPreview.jsx';
import GalleryDisplay from './GalleryDisplay.jsx';
import { getColorFilters } from './scripts/colorfilter.js';
import FontChooser from './FontChooser';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import * as quoteGenerator from './quotes.js';
import * as smartcrop from 'smartcrop';


const getSquareCropForImage = async image => {
  let rect = image.getBoundingClientRect();
  let oblong = rect.x > rect.y;
  let size = oblong ? { width: rect.height, height: rect.height } : { width: rect.width, height: rect.width };
  const crop = await smartcrop.crop(image, size);
  if (crop) {
    let returnValue = { ...crop.topCrop };
    // returnValue.originalSize = size;
    // returnValue.x = ((crop.x / size.width) * 100) + '%';
    // returnValue.y = ((crop.y / size.height) * 100) + '%';
    // returnValue.width = (crop.x / size.x) * 100 + '%';
    // returnValue.height = (crop.x / size.x) * 100 + '%';
    console.log('retruning', returnValue);
    return returnValue;
  }
};

let mapProvider;
const liveBgPhoneUrl = 'https://cms.eggborne.com/galaxys.png';
let testMode = process.env.NODE_ENV === 'development';
const selfRootUrl = testMode ? 'http://localhost:3001' : 'https://cms.eggborne.com';
window.IS_LANDSCAPE = window.innerWidth > window.innerHeight;

const offBlack = '#101010';
const offWhite = '#efefef';

let viewHeight;
let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
let weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];

let saveInterval;
let lastChanged = 0;
let lastAutoSaved = 0;

window.addEventListener('load', () => {
  console.log(process.env);
  viewHeight = window.visualViewport ? window.visualViewport.height : window.innerHeight;
  document.documentElement.style.setProperty('--screen-height', viewHeight + 'px');
  // let image = new Image();
  // image.crossOrigin = 'Anonymous';
  // image.src = 'https://cms.eggborne.com/wagsworth/assets/images/gallery/doggo4.jpg';
  // image.addEventListener('load', async () => {
  //   console.log('imag??', image);
  //   let crop = await getSquareCropForImage(image);
  //   console.log('crop', crop)
  // });
});

Array.prototype.swap = function(x, y) {
  let b = this[x];
  this[x] = this[y];
  this[y] = b;
  return this;
};

const setCookie = (cname, cvalue, exdays) => {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  console.warn('CMS COOKIE SET.');
};
const getCookie = cookieName => {
  let cookieObj;
  let name = cookieName + '=';
  let decodedCookie = decodeURIComponent(document.cookie).split('; ');

  cookieObj = decodedCookie.filter(str => str.split('=')[0] === cookieName);
  if (cookieObj.length === 1) {
    cookieObj = JSON.parse(cookieObj[0].split('=')[1]);
  } else {
    cookieObj = undefined;
  }
  console.warn('got cookie', cookieObj);
  return cookieObj;
};

let liveUrl;

const logUserIn = (username, pass, token) => {
  console.log('logging in', username);
  return axios({
    method: 'post',
    url: `https://cms.eggborne.com/php/cms${testMode ? 'test' : ''}loguserin.php`,
    headers: {
      'Content-type': 'application/x-www-form-urlencoded'
    },
    data: {
      username: username,
      pass: pass,
      token: token
    }
  });
};
const createGuestUser = () => {
  console.log('attempt create');
  return axios({
    method: 'post',
    url: 'https://cms.eggborne.com/php/cmscreateguestuser.php',
    headers: {
      'Content-type': 'application/x-www-form-urlencoded'
    }
  });
};
const logGuestUserIn = (id, token) => {
  console.log('logging in geust', id, token);
  return axios({
    method: 'post',
    url: `https://cms.eggborne.com/php/cmslogguestuserin.php`,
    headers: {
      'Content-type': 'application/x-www-form-urlencoded'
    },
    data: {
      id: id,
      token: token
    }
  });
};
const getContentForUser = (username, token) => {
  return axios({
    method: 'post',
    url: `https://cms.eggborne.com/php/cmsgetusercontent.php`,
    headers: {
      'Content-type': 'application/x-www-form-urlencoded'
    },
    data: {
      username: username,
      token: token
    }
  });
};
const getContentForGuest = (username, token) => {
  return axios({
    method: 'post',
    url: `https://cms.eggborne.com/php/getguestcontent.php`,
    headers: {
      'Content-type': 'application/x-www-form-urlencoded'
    },
    data: {
      username: username,
      token: token
    }
  });
};
const getBackupsForUser = username => {
  console.warn('getb for', username);
  return axios({
    method: 'post',
    url: 'https://cms.eggborne.com/php/getbackupcontent.php',
    headers: {
      'Content-type': 'application/x-www-form-urlencoded'
    },
    data: {
      username: username
    }
  });
};
const writeToJSON = (id, token, username, siteName, themeName, jsonData) => {
  themeName = themeName || 'geraldo';
  let now = Date.now();
  let data = {
    id: parseInt(id),
    token: token,
    username: username,
    siteName: siteName,
    themeName: themeName,
    jsonData: jsonData,
    lastUpdated: parseInt(now)
  };
  console.warn('SENDING2 to JSON', data);
  return axios({
    method: 'post',
    url: `https://cms.eggborne.com/${siteName}/php/cmswritetojson.php`,
    headers: {
      'Content-type': 'application/x-www-form-urlencoded'
    },
    data: data
  });
};
const getLocalJSON = async (siteName, fileName) => {
  siteName = 'wagsworth';
  console.warn('GETING LOCAL --------------------------', fileName);
  let response = await fetch(`https://cms.eggborne.com/${siteName}/themes/${fileName}`);
  console.log('esp wads', response);
  let jsonFile = await response.json();
  console.log('local content is', jsonFile);
  return jsonFile;
};
const getJSONList = (id, token, username, siteName) => {
  siteName = 'wagsworth';
  let data = {
    id: parseInt(id),
    token: token,
    username: username,
    siteName: siteName
  };
  console.warn('SENDING to JSON', data);
  return axios({
    method: 'post',
    url: `https://cms.eggborne.com/${siteName}/php/cmsgetthemes.php`,
    headers: {
      'Content-type': 'application/x-www-form-urlencoded'
    },
    data: data
  });
};
const updateUserData = (username, siteName, attribute, newValue, globalStyles, images, id, token) => {
  let now = Date.now();
  if (globalStyles.backgroundImagePreviewUrl !== globalStyles.backgroundImageUrl) {
    globalStyles.backgroundImageUrl = globalStyles.backgroundImagePreviewUrl;
  }
  let data = {
    siteName: siteName,
    id: parseInt(id),
    token: token,
    attribute: attribute,
    sections: JSON.stringify(newValue.sections),
    globalStyles: JSON.stringify(globalStyles),
    images: JSON.stringify(images),
    faqs: JSON.stringify(newValue.faqs),
    requirements: JSON.stringify(newValue.requirements),
    fonts: JSON.stringify(newValue.fonts),
    lastUpdated: parseInt(now),
    username: username
  };
  console.warn('SENDING', data);
  return axios({
    method: 'post',
    url: `https://cms.eggborne.com/php/cms${testMode ? 'test' : ''}updateuser.php`,
    headers: {
      'Content-type': 'application/x-www-form-urlencoded'
    },
    data: data
  });
};
const updateUserCategory = (username, siteName, token, category, newValue) => {
  let data = {
    username,
    siteName,
    token,
    category,
    newValue: JSON.stringify(newValue)
  };
  console.warn('SENDING', data);
  return axios({
    method: 'post',
    url: `https://cms.eggborne.com/php/cmsupdatecategory.php`,
    headers: {
      'Content-type': 'application/x-www-form-urlencoded'
    },
    data: data
  });
};
const changePassword = (userData, oldPass, newPass) => {
  let data = {
    username: userData.username,
    id: parseInt(userData.id),
    oldPass,
    newPass
  };
  console.warn('SENDING', data);
  return axios({
    method: 'post',
    url: 'https://cms.eggborne.com/php/cmschangepass.php',
    headers: {
      'Content-type': 'application/x-www-form-urlencoded'
    },
    data: data
  });
};

const attemptUserCreation = loginObj => {
  console.log('attempt create', loginObj);
  return axios({
    method: 'post',
    url: 'https://cms.eggborne.com/php/cmscreateuser.php',
    headers: {
      'Content-type': 'application/x-www-form-urlencoded'
    },
    data: JSON.stringify(loginObj)
  });
};

const AppBody = styled.div`
  position: relative;
  min-height: var(--screen-height);
  display: flex;
  flex-direction: column;
  ${props => (props.isWindow ? `align-items: center;` : `justify-content: space-between;`)}
  /* padding-top: calc(var(--main-font-size) * 3); */
  background-color: #9e9eb4;
  transition: opacity 400ms ease, transform 600ms ease;
  padding-top: var(--header-height);
  max-width: 100vw;

  & .global-controls {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto 1fr;
    justify-items: center;
    align-items: center;
    width: 100%;
    max-width: 100vw;
    padding: 0.5rem;
  }
  & .global-controls > .global-section > div:first-child {
    font-weight: bold;
  }
  & .global-controls > .global-section {
    grid-column-end: span 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 100vw;
    padding: 0.5rem;
    padding-top: 1rem;
  }
  & .global-controls > .global-section:nth-of-type(4) {
    /* height: 100%; */
  }
  & .global-controls > div {
    /* padding: 0 1.5rem; */
  }
  & .global-section button {
    color: black;
  }
  & .global-section span.MuiSlider-root {
    margin-top: 1rem !important;
    width: 90%;
  }
  & .global-controls #logo-color-select {
    grid-row-start: 3;
    display: flex;
    flex-direction: row;
    width: 50%;
    & > *:first-child {
      margin-right: 0.5rem;
    }
  }
  & .global-controls #logo-color-select .radio-choice.selected {
    border-color: green !important;
  }
  & .global-controls .floating-label-box:after {
    background-color: #9e9eb4;
  }
  & .background-controls .floating-label-box:after {
    background-color: #777;
  }
  & #title-font-select:after {
    content: 'Title Font';
  }
  & #main-font-select:after {
    content: 'Main Font';
  }
  & #logo-color-select:after {
    content: 'Logo Color';
  }
  & .global-section:last-of-type {
    grid-column-end: span 2;
    grid-row-start: 4;
    width: 100%;
    margin-top: 1rem;
  }
  & #title-legend-input p {
    text-shadow: none;
    font-size: 1.5rem !important;
    font-weight: normal;
    color: black;
    white-space: unset;
  }
  & #title-legend-input:after {
    content: 'Title Legend';
  }
  & .background-controls {
    grid-column-start: 1;
    grid-column-end: span 2;
    grid-row-end: span 4;
    background: #777;
    border: 0.05rem solid #555;
    border-radius: 4px;
    width: 100%;
    /* justify-self: stretch; */
    /* margin: 0 2%; */
    padding: 3%;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto calc(var(--header-height) * 1.25) var(--header-height) var(--header-height);
    grid-column-gap: 0.5rem;
    grid-row-gap: 1rem;
    color: white;
    font-weight: bold;
    text-shadow: -1px -1px 0 #00000088, 1px -1px 0 #00000088, -1px 1px 0 #00000088, 1px 1px 0 #00000088;
    height: auto;

    & .MuiSlider-mark,
    .MuiSlider-markActive {
      width: 0.1rem !important;
      transform: translateY(-50%);
      top: 50%;
      height: 1rem !important;
      opacity: 0.5;
    }
    & .MuiSlider-marked {
      margin: 0 !important;
      padding: 1rem 0 !important;
    }
    & .MuiSlider-markLabel {
      background: red;
      width: 8px;
      display: none;
    }
  }
  & .background-controls > h4 {
    margin: 0;
    grid-column-end: span 2;
    justify-self: start;
    text-align: left;
  }
  & .background-controls > button {
    margin: 0.5rem;
    width: 80%;
  }
  & .background-controls > .background-size-control {
    position: relative;
    grid-column-start: 1;
    /* grid-row-start: 4; */
    grid-column-end: span 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border: 0.05rem solid #555;
    border-radius: inherit;
    padding: 0.5rem 1rem;
    /* margin-top: 1rem; */
  }
  & .background-controls > .background-size-control > h4:first-child {
    position: absolute;
    top: -0.5rem;
    left: 0.25rem;
    padding: 0 0.25rem;
    font-size: 0.85rem;
    background: #777;
  }
  & .background-controls > .background-size-control:last-child {
    grid-row-start: 5;
  }
  & .background-controls > .half-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-column-end: span 2;
  }
  & .background-controls .floating-label-box {
    grid-column-start: 1;
    grid-row-start: 3;
    height: 100%;
  }
  & #background-stretch-select {
  }
  & #background-tint-select:after {
    content: 'Base Color';
  }
  & #background-stretch-select:after {
    content: 'Stretch to fit';
  }
  &:not(.landed) {
    opacity: 0;
  }
  & > .Card {
    width: calc(var(--main-font-size) * 16);
    opacity: 0;
  }
  & > .Card .Button {
    margin-top: var(--main-font-size);
    margin-bottom: calc(var(--main-font-size) / 2);
    width: calc(var(--main-font-size) * 5);
    transition: color 310ms ease, opacity 310ms ease;
  }
  & > .Card .Button.disabled {
    color: #aaa;
    opacity: 0.6;
    pointer-events: none;
  }
  & > footer {
    position: fixed;
    /* top: calc(var(--screen-height) - var(--footer-height)); */
    bottom: 0;
    padding-left: calc(var(--footer-height) * 0.1);
    left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    max-width: 100vw;
    height: var(--footer-height);
    background: var(--header-color);
    z-index: 2;
  }
  & .upper-tab-area {
    position: fixed;
    bottom: var(--footer-height);
    left: 0;
    /* transform: translateY(-100%); */
  }
  & .upper-tab-area > button {
    height: 2.25rem;
    min-width: 25vw;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: #101010 !important;
  }
  & .upper-tab-area > button:active,
  .upper-tab-area > button:visited .upper-tab-area > button:hover {
    background: initial;
  }

  & .upper-tab-area > button:not(.selected) {
    background-color: #303030 !important;
    color: #aaa;
  }
  & .switch-area .switch-label {
    color: ${props => (props.autoSave ? '#afa' : '#ffffffaa')};
  }
  & .switch-area {
    font-size: calc(var(--footer-height) * 0.22);
    display: flex;
    align-items: center;
  }
  & .footer-button-bar {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }
  & .footer-button-group.hidden {
    opacity: 0;
    pointer-events: none;
  }
  & .footer-button-group {
    position: absolute;
    display: flex;
    justify-content: flex-start;
  }

  & .footer-button-group > button {
    padding: 0 0.75rem;
    margin: 0;
    margin-right: calc(var(--footer-height) * 0.1);
  }
  & .live-view-area {
    /* display: none; */
  }
  & .live-view-area,
  .footer-divider {
    /* display: none; */
  }
  & .live-view-area button {
    background-color: darkgreen;
    border-radius: 0;
    border-style: outset;
    border-width: 0.1rem;
    border-color: #595;
  }
  & .live-view-area button:active {
    border-style: inset !important;
  }
  & .live-view-area button:hover {
    background-color: green !important;
  }
  & .theme-area button {
    background-color: blue;
  }
  & .theme-area button:last-of-type {
    background-color: purple;
  }
  & .footer-button-group > .footer-option-tab {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 0.75rem;
    color: white;
    background: var(--header-color);
    height: calc(var(--footer-height) * 0.4);
    padding: calc(var(--footer-height) * 0.1) calc(var(--footer-height) * 0.2);
    z-index: 1;
    transform: translateY(-100%);
    border-top-right-radius: calc(var(--footer-height) * 0.075);
    border-top-left-radius: calc(var(--footer-height) * 0.075);
  }
  & .footer-button-group > .footer-option-tab {
    display: none;
  }
  & button {
    height: calc(var(--footer-height) * 0.7);
    margin: 0 calc(var(--footer-height) * 0.05);
    min-width: 5rem;
    color: white;
    font-size: 0.8rem;
    background: green;
    line-height: 1rem;
  }
  & button:hover {
    background: #060;
  }
  & .footer-button-group > button:disabled {
    opacity: 0.5;
    color: gray;
    pointer-events: none;
  }
  #change-password-modal input button {
    background: red;
  }
  @media (orientation: landscape) and (min-width: 600px) {
    & .global-controls {
      display: grid;
      grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
      grid-template-rows: auto auto auto 1fr;
      padding: 1rem 2rem;
      /* grid-template-rows: calc(var(--header-height) * 2.5); */
      /* justify-items: center; */
      /* align-items: center; */
      width: 100%;
      max-width: 100%;
    }
    & .global-controls > .global-section {
      width: 90%;
    }
    & .global-controls > .font-area {
      grid-row-start: 1;
      grid-column-start: 1;
      grid-column-end: span 1;

      &:nth-of-type(2) {
        grid-column-start: 2;
      }
    }
    & .global-controls > .logo-color-select {
      grid-row-start: 2;
    }
    & .global-controls > .logo-color-select > .radio-choice {
      /* width: var(--header-height); */
    }
    & .background-controls {
      grid-row-start: 1;
      grid-column-start: 3;
      grid-column-end: span 2;
    }
    & .background-controls > .half-row {
      grid-column-end: span 1;
    }
    & > footer {
      justify-content: space-between;
    }
    & .live-view-area,
    .footer-divider {
      display: unset;
    }
    & .footer-button-bar {
      /* width: min-content; */
      /* position: relative; */
    }
    & .footer-button-group {
      position: relative;
      left: 0;
      display: flex !important;
      width: max-content;
    }
    & .footer-button-group button {
    }
    & .footer-button-group > .footer-option-tab {
      display: flex !important;
      top: 0;
      /* left: 0; */
      font-size: 0.75rem;
      color: white;
      background: var(--header-color);
      height: calc(var(--footer-height) * 0.4);
      padding: calc(var(--footer-height) * 0.1) calc(var(--footer-height) * 0.2) !important;
      z-index: 1;
      transform: translateY(-100%);
      border-top-right-radius: calc(var(--footer-height) * 0.075);
      border-top-left-radius: calc(var(--footer-height) * 0.075);
    }
    & .footer-button-group > .footer-option-tab {
      padding: 0 1rem;
      display: flex;
      align-items: center;
      top: (-150%) !important;
    }
    & .upper-tab-area {
      display: none;
    }
  }
`;
const PanelArea = styled.div`
  position: relative;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  width: 100%;
  font-size: var(--main-font-size);
  padding-bottom: var(--footer-height);

  & > * {
    position: relative;
    /* flex-basis: 45%; */
    width: 100%;
    /* flex-basis: 40%; */
    flex-grow: 1;
    margin: 1%;
    text-align: left;
    padding: 1rem;
  }
  & .service-row {
    padding: 0 20%;
    font-size: 2rem;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .service-row > p {
    margin: 0.75rem 0;
  }
  & > * > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    font-size: 1.75rem;
    padding: 1rem;
    padding-bottom: 1.5rem;
  }
  & header > div {
    flex-grow: 1;
  }
  & > * > div:not(.multi-paragraph) {
    padding: 5%;
  }
  & header > button {
    line-height: 1.1;
    width: 5rem;
  }
  @media (orientation: landscape) and (min-width: 600px) {
    & > * {
      position: relative;
      /* flex-basis: 45%; */
      max-width: 48%;
      flex-grow: 1;
      margin: 1%;
      /* text-align: left; */
      padding: 1rem;
    }
  }
`;

const BackgroundSample = styled.div`
  position: relative;
  background-image: ${props => props.backgroundImage};
  background-position: center;
  /* background-size: ${props => props.backgroundImageSize}%; */
  background-size: ${props => props.backgroundImageSize}%;
  background-repeat: no-repeat;
  width: calc(var(--header-height) * 2 * (16 / 9));
  min-height: calc(var(--header-height) * 2);
  grid-row-end: span 2;
  pointer-events: auto;
  outline: 1px solid black;
  
  &::after {
    pointer-events: none;
    opacity: ${props => props.opacity};
    position: absolute;
    top: 0;
    left: 0;
    width: calc(var(--header-height) * 2 * (16 / 9));
    height: 100%;
    content: '';
    background-color: ${props => props.tint};
  }
`;

function App(props) {
  const [loaded, setLoaded] = useState(false);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [userData, setUserData] = useState({});
  const [originalData, setOriginalData] = useState(undefined);
  const [userEditedData, setUserEditedData] = useState({});
  const [themes, setThemes] = useState({});
  const [modalShowing, setModalShowing] = useState(undefined);
  const [inputStatus, setInputStatus] = useState({
    working: false,
    badPassword: false,
    badUser: false
  });
  const [autoSave, setAutoSave] = useState(false);
  const [saveStatus, setSaveStatus] = useState('');
  const [saveMessage, setSaveMessage] = useState('');
  const [saveLegend, setSaveLegend] = useState('');
  const [editedSections, setEditedSections] = useState([]);
  const [popupMessageActive, setSaveMessageActive] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [selectedSection, setSelectedSection] = useState(undefined);
  const [backupContentList, setBackupContentList] = useState([]);
  const [redoContent, setRedoContent] = useState(undefined);
  const [undoContent, setUndoContent] = useState(undefined);
  const [liveWindow, setLiveWindow] = useState(false);
  const [liveViewOn, setLiveViewOn] = useState(false);
  const [liveViewOrientation, setLiveViewOrientation] = useState('portrait');
  const [addressOk, setAddressOk] = useState(true);
  const [userSelectedImages, setUserSelectedImages] = useState([]);
  const [footerMenuOpen, setFooterMenuOpen] = useState(false);
  const [bannerLine, setBannerLine] = useState(false);
  const [editMode, setEditMode] = useState('style');
  const [footerOptionSelected, setFooterOptionSelected] = useState('live-view');

  useEffect(() => {
    // let viewHeight = window.visualViewport ? window.visualViewport.height : window.innerHeight;
    // console.error('useEffect vh VS vvh', window.innerHeight, viewHeight);
    let foundCookie = getCookie('eggborne-cms');
    if (foundCookie) {
      console.log('logging in with cookie', foundCookie);
      logUserIn(foundCookie.username, 'chickens', foundCookie.token).then(response => {
        if (response.data && response.data.id && response.data.token) {
          liveUrl = testMode
            ? `http://localhost:3000/?username=${foundCookie.username}&token=${response.data.token}`
            : `https://eggborne.com/wag/?username=${foundCookie.username}&token=${response.data.token}`;
          getUserContent(response.data, foundCookie);
        } else {
          alert('BAD TOKEN YO');
          setCookie('eggborne-cms', null, 0);
          setCookie('eggborne-cms', null, 0);
          console.log('bunk response', response);
        }
      });
    } else {
      setLoaded(true);
    }
    // let uploadFrame = document.createElement('iframe');
    // uploadFrame.setAttribute('id', 'image-upload-frame');
    // console.log('upfram', uploadFrame)
    // uploadFrame.addEventListener('click', () => {
    //   console.warn('upload frame loaded!')
    // });
    setBannerLine(quoteGenerator.getLine(100));
  }, []);

  const liveOpen = () => {
    console.log('closd?', liveWindow.closed);
    return !liveWindow.closed;
  };

  const getUserContent = (userData, foundCookie) => {
    getContentForUser(userData.username, foundCookie.token).then(contResponse => {
      console.log('contResponse.data', contResponse.data);
      for (let attr in contResponse.data) {
        console.log('doing attr', attr);
        // contResponse.data[attr] = contResponse.data[attr].replace(/`/g, "'");
        contResponse.data[attr] = JSON.parse(contResponse.data[attr]);
      }
      let newData = {
        ...userData,
        ...foundCookie,
        content: contResponse.data,
        lastUpdated: contResponse.data.lastUpdated,
        globalStyles: contResponse.data.globalStyles,
        images: contResponse.data.images
      };
      newData.content.sections = cleanSections(newData.content.sections, true);
      newData.content.faqs = cleanSections(newData.content.faqs, true);
      newData.content.requirements = cleanSections(newData.content.requirements, true);
      console.log('images gallery', newData.images.gallery);
      // newData.images.gallery.map(async (image, i) => {
      // console.log('on img', i, image)
      // let testImg = new Image();
      // testImg.src = image.url;
      // let crop = await getSquareCropForImage(testImg);
      // console.log('newdtadtat we got a crop of', crop)
      // let rect = crop.originalSize;
      // image.crop = {};
      // image.crop.x = ((crop.x / rect.width) * 100) + '%';
      // image.crop.y = ((crop.y / rect.height) * 100) + '%';
      // image.crop.width = (crop.width / rect.width) * 100 + '%';
      // image.crop.height = (crop.height / rect.height) * 100 + '%';
      // image.url = `https://cms.eggborne.com/${newData.siteName}/assets/images/gallery/${image.fileName}`;
      // console.log('imagess image', image)
      // newData.images[i] = image;
      // });
      setUserData(newData);
      setUserEditedData({ ...newData });
      console.log('inintially set userData as', newData);
      setCookie('eggborne-cms', JSON.stringify(foundCookie), 365);
      console.log('SETTING LOGGED IN!');
      setLoaded(true);
      setSaveStatus('saved');
      setSaveLegend(timeSinceUpdated(true, contResponse.data.lastUpdated));
      requestIdleCallback(() => {
        setUserLoggedIn(true);
      });
    });
  };
  const getGuestContent = (guestUserData, filled) => {
    const contentUser = filled ? `Guest-${filled}` : `Guest-empty`;
    console.log('sendiong for', contentUser)
    getContentForGuest(contentUser, guestUserData.token).then(contResponse => {
      console.log('getContentForGuest.data', contResponse.data);
      console.log('guestUserData is', guestUserData);
      for (let attr in contResponse.data) {
        console.log('doing attr', attr);
        // contResponse.data[attr] = contResponse.data[attr].replace(/`/g, "'");
        contResponse.data[attr] = JSON.parse(contResponse.data[attr]);
      }
      let newData = {
        ...guestUserData,
        content: contResponse.data,
        lastUpdated: contResponse.data.lastUpdated,
        globalStyles: contResponse.data.globalStyles,
        images: contResponse.data.images
      };
      newData.content.sections = cleanSections(newData.content.sections, true);
      newData.content.faqs = cleanSections(newData.content.faqs, true);
      newData.content.requirements = cleanSections(newData.content.requirements, true);
      setUserData(newData);
      setUserEditedData({ ...newData });
      console.log('inintially set userData as', newData);
      setLoaded(true);
      requestIdleCallback(() => {
        setUserLoggedIn(true);
      });
    });
  };

  const timeSinceUpdated = useCallback(
    (timeOnly, specificTime) => {
      let nowMs = Date.now();
      let updatedMs = specificTime ? parseInt(specificTime) : parseInt(userData.lastUpdated);
      const currentDate = new Date(nowMs);
      const lastUpdatedDate = new Date(updatedMs);
      const difference = new Date(nowMs - updatedMs);

      let niceTime = '';
      let sameMinute = currentDate.getMinutes() === lastUpdatedDate.getMinutes();
      let sameDay = sameMinute || currentDate.getDay() === lastUpdatedDate.getDay();
      let sameMonth = sameDay || currentDate.getMonth() === lastUpdatedDate.getMonth();
      let sameYear = sameMonth || currentDate.getFullYear() === lastUpdatedDate.getFullYear();

      let dayOfMonth = currentDate.getDate() !== lastUpdatedDate.getDate() ? lastUpdatedDate.getDate() : ``;
      let day = !sameDay ? weekDays[parseInt(lastUpdatedDate.getDay())] + ' ' : timeOnly ? '' : 'Today';
      let month = !sameDay ? months[parseInt(lastUpdatedDate.getMonth())] + ' ' : ``;
      let year = !sameYear ? `, ${lastUpdatedDate.getFullYear()} ` : ``;

      let time = sameYear ? lastUpdatedDate.toLocaleTimeString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' }) : ``;

      // console.log('day', day)
      // console.log('month', month)
      // console.log('dayOfMonth', dayOfMonth)
      // console.log('year', year)
      // console.log('time', time)

      niceTime = `${day}${month}${dayOfMonth}${year} ${time}`;

      return niceTime;
    },
    [userData.lastUpdated]
  );

  // useEffect(() => {
  //   console.warn('cahgned that editedSections now');
  // }, [editedSections, userEditedData]);

  const handleSubmitLogin = async (username, pass) => {
    setInputStatus({ ...inputStatus, working: true });
    // let response = await attemptUserCreation({username: username, pass: pass});
    let response = await logUserIn(username, pass, 'balls');
    if (response.data.id && response.data.token) {
      setCookie('eggborne-cms', JSON.stringify({ username: username, token: response.data.token }), 365);
      let foundCookie = getCookie('eggborne-cms');
      liveUrl = testMode ? `http://localhost:3000/?username=${username}&token=${response.data.token}` : `https://eggborne.com/fancy/?username=${username}&token=${response.data.token}`;
      getUserContent(response.data, foundCookie);
    } else {
      if (response.data === 'WRONG PASSWORD.') {
        setInputStatus({ ...inputStatus, badPassword: true });
        setTimeout(() => {
          setInputStatus({ ...inputStatus, working: false, badPassword: false });
        }, 1600);
      }
      if (response.data === 'NO SUCH USER.') {
        setInputStatus({ ...inputStatus, badUser: true });
        setTimeout(() => {
          setInputStatus({ ...inputStatus, working: false, badUser: false });
        }, 1600);
      }
    }
  };
  const handleClickLogInAsGuest = async () => {
    let newGuest = await createGuestUser();
    console.log('GUEST??', newGuest);
    if (newGuest && newGuest.data) {
      let guestUsername = newGuest.data[0];
      let guestId = newGuest.data[1];
      let guestToken = newGuest.data[2];
      let newUserData = { ...userData };
      newUserData.username = guestUsername;
      newUserData.id = guestId;
      newUserData.token = guestToken;
      newUserData.siteName = 'fancy';
      setUserData(newUserData);
      setUserEditedData({ ...newUserData });
      setInputStatus({ ...inputStatus, working: true });
      let response = await logGuestUserIn(guestId, guestToken);
      if (response && response.data.id && response.data.token) {
        console.warn('GUES LOGGED IN!! ----------------------------');
        liveUrl = `https://eggborne.com/fancy/?username=${guestUsername}&token=${response.data.token}`;
        if (testMode) {
          liveUrl = `http://localhost:3000/?username=${guestUsername}&token=${response.data.token}`;
        }
        getGuestContent(newUserData);
      } else {
        console.error('handleSubmitGuestLogin no bueno');
      }
    } else {
      console.error('no create guesto');
    }
  };
  const getRecentContent = async id => {
    let response = await getBackupsForUser(`${id}-backup`);
    if (response.data) {
      let contentList = response.data.toString();
      let newList = [];
      contentList = contentList.substr(0, contentList.length - 2);
      contentList = contentList.split('~~');
      contentList.forEach((entry, e) => {
        newList[e] = JSON.parse(JSON.parse(entry));
      });
      setBackupContentList(newList);
      return newList[1];
    }
  };
  const wait = async duration => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, duration);
    });
  };
  const cleanseString = (str, uncleanse) => {
    let newString;
    if (!uncleanse) {
      newString = str
        .replace(/"/g, '|qq|')
        .replace(/'/g, '`')
        .replace(/\\/g, '/');
    } else {
      newString = str.replace(/\|qq\|/g, '"').replace(/`/g, "'");
    }
    return newString;
  };
  const cleanSections = (sectionsArr, unclean) => {
    sectionsArr.map((sec, s) => {
      for (let subSec in sec) {
        if (typeof sec[subSec] === 'string') {
          sec[subSec] = cleanseString(sec[subSec], unclean);
        } else if (Array.isArray(sec[subSec])) {
          sec[subSec].map((subSubSec, b) => {
            if (typeof subSubSec === 'string') {
              sec[subSec][b] = cleanseString(sec[subSec][b], unclean);
            } else if (Array.isArray(subSubSec)) {
              console.warn('found deep array in', sec.title, subSec, subSubSec);
            } else if (typeof subSubSec === 'object') {
              for (let item in subSubSec) {
                if (typeof subSubSec[item] === 'string') {
                  subSubSec[item] = cleanseString(subSubSec[item], unclean);
                } else if (Array.isArray(subSubSec[item])) {
                  subSubSec[item].map((nano, n) => {
                    if (typeof nano === 'string') {
                      subSubSec[item][n] = cleanseString(nano, unclean);
                    } else if (Array.isArray(nano)) {
                      nano.forEach((para, p) => {
                        if (typeof para === 'string') {
                          nano[p] = cleanseString(para, unclean);
                        }
                      });
                    }
                  });
                } else if (typeof subSubSec[item] === 'object') {
                  for (let nano in subSubSec[item]) {
                    if (typeof subSubSec[nano] === 'string') {
                      subSubSec[nano] = cleanseString(subSubSec[nano], unclean);
                    } else if (Array.isArray(subSubSec[nano])) {
                      subSubSec[nano].forEach((para, p) => {
                        if (typeof para === 'string') {
                          subSubSec[nano][p] = cleanseString(para, unclean);
                        }
                      });
                    }
                  }
                }
              }
            }
          });
        } else if (typeof subSec === 'object') {
          for (let item in subSec) {
            if (typeof subSec[item] === 'string') {
              subSec[item] = cleanseString(subSec[item], unclean);
            }
          }
        }
      }
    });
    return sectionsArr;
  };
  const updateLiveView = (newUserData, newSaveStatus) => {
    newUserData.saveStatus = newSaveStatus || saveStatus;
    if (window.liveWindow && !window.liveWindow.closed) {
      let frameDoc = window.liveWindow.document.getElementById('live-frame');
      // window.liveWindow.focus();
      frameDoc.contentWindow.postMessage(newUserData, '*');
    }
    if (autoSave) {
      setSaveLegend('editing...');
    }
  };

  const handleClickLoadTheme = async () => {
    requestIdleCallback(() => {
      setModalShowing('loadTheme');
    });
    const response = await getJSONList(userData.id, userData.token, userData.username, userData.siteName);
    console.warn('handleClickLoadTheme response', response.data);
    if (response.data.indexOf('no bueno') !== -1) {
    } else if (response.data.length) {
      let themes = response.data;
      let jsonArray = [];
      for (let i = 0; i < themes.length; i++) {
        let theme = themes[i];
        let contents = await getLocalJSON(userData.siteName, theme.fileName);
        console.log('got contents', contents);
        let name = theme.fileName.split('_')[2];
        let creator = theme.fileName.split('_')[theme.fileName.split('_').length - 1].split('.')[0];
        jsonArray.push({
          name,
          creator,
          contents
        });
      }
      console.log('made jsonArray', jsonArray);
      setThemes(jsonArray);

      // const newUserData = {...userEditedData};
      // jsonArray.forEach(entry => {
      //   console.log(entry.jsonFile);
      //   entry.jsonFile.sections.forEach((sec, s) => {
      //     newUserData.content.sections[s].style = sec.style;
      //   })
      //   newUserData.globalStyles = entry.jsonFile.globalStyles;
      //   newUserData.fonts = entry.jsonFile.fonts;
      // });
      // updateLiveView(newUserData);
      // setUserData(newUserData);
      // setUserEditedData({ ...newUserData });
    }
  };
  const handleClickSaveTheme = async (e, renamed) => {
    let userThemeName = renamed ? renamed : prompt('Enter a name for your theme.');
    if (!userThemeName) {
      return;
    }
    setSaveStatus('savingTheme');
    const newUserData = { ...userEditedData };
    let newStyles = { sections: [], globalStyles: newUserData.globalStyles };
    newUserData.content.sections.forEach((sec, i) => {
      newStyles.sections[i] = { style: sec.style };
    });
    console.warn('handleClickSaveTheme bundling send data:', newStyles);
    const response = await writeToJSON(userData.id, userData.token, userData.username, 'wagsworth', userThemeName, newStyles);
    console.warn('handleClickSaveTheme response', response);

    if (response.data.indexOf('no bueno') !== -1) {
      setSaveStatus('error');
    } else if (response.data.indexOf('exists') !== -1) {
      let overwrite = window.confirm(`Theme name "${userThemeName}" already exists. Overwrite?`);
      if (overwrite) {
        userThemeName && handleClickSaveTheme(userThemeName);
      } else {
        let newName = prompt('Enter a new name.');
        newName && handleClickSaveTheme(newName);
      }
    } else {
      setSaveStatus('saved');
      setPopupMessage('SAVED THEME!');
      setSaveMessageActive(true);
      await wait(1200);
      setSaveMessageActive(false);
    }
  };
  const handleClickSaveData = async () => {
    setSaveStatus('saving');
    // recordforUndo(userData)
    // let newUserData = JSON.parse(JSON.stringify({...userEditedData}));
    let newUserData = { ...userEditedData };

    newUserData.content.sections = cleanSections(newUserData.content.sections);
    newUserData.content.faqs = cleanSections(newUserData.content.faqs).filter(qSet => qSet.question && qSet.answer);
    // newUserData.content.faqs = newUserData.content.faqs.filter(qSet => qSet.question && qSet.answer);
    newUserData.content.requirements = cleanSections(newUserData.content.requirements).filter(qSet => qSet.headline && qSet.bodyText);
    // newUserData.content.requirements = newUserData.content.requirements.filter(qSet => qSet.headline && qSet.bodyText);

    console.warn('bundling to send', newUserData);
    console.warn('bundling send coords', newUserData.content.sections);

    let response = await updateUserData(userData.username, userData.siteName, 'content', newUserData.content, newUserData.globalStyles, userData.images, userData.id, userData.token);
    console.warn('handleClickSaveData response', response);
    let styleResponse = response;

    if (response.data === 'UPDATED AND BACKED UP' && styleResponse.data === 'UPDATED AND BACKED UP') {
      // newUserData = {...userEditedData};
      setEditedSections([]);
      newUserData.lastUpdated = parseInt(Date.now());
      newUserData.content.sections = cleanSections(newUserData.content.sections, true);
      newUserData.content.faqs = cleanSections(newUserData.content.faqs, true);
      updateLiveView(newUserData, 'saved');
      setUserData(newUserData);
      setUserEditedData({ ...newUserData });
      setSaveStatus('saved');
      setSaveLegend(`at ${timeSinceUpdated(true, newUserData.lastUpdated)}`);
      if (!autoSave) {
        setPopupMessage('SAVED!');
        setSaveMessageActive(true);
        await wait(300);
        await wait(900);
        setSaveMessageActive(false);
        requestIdleCallback(() => {
          setBannerLine(quoteGenerator.getLine(20));
        });
      }
    }
  };
  const handleClickChangePassword = () => {
    setModalShowing('changePassword');
  };
  const handleClickChangeMode = newMode => {
    console.log('newMode', newMode);
    setEditMode(newMode);
  };
  const handleChangeField = async e => {
    setSaveStatus('unsaved');
    console.log(e.target.id, 'changed field');
    let splitId = e.target.id.split('-');
    console.log('splitId', splitId);
    let segment1 = splitId[0];
    let segment1Index = splitId[1];
    let segment2 = splitId[2];
    let segment2Index = splitId[3];
    let segment3 = splitId[4];
    let segment3Index = splitId[5];
    let segment4 = splitId[6];
    let segment4Index = splitId[7];

    if (!editedSections.includes(segment1Index)) {
      let newEdited = [...editedSections];
      newEdited.push(parseInt(segment1Index));
      setEditedSections(newEdited);
    }
    // let newEditedData = {...userEditedData};
    let newEditedData = getCopy(userEditedData);
    let newText = e.target.innerText.trim();

    if (e.target.classList.contains('multi-paragraph')) {
      let newParagraphArray = [];
      let paragraphs = [...e.target.children];
      paragraphs.forEach(paraEl => {
        if (paraEl.innerText.trim()) {
          newParagraphArray.push(paraEl.innerText.trim());
        }
      });
      if (splitId.includes('faqs')) {
        if (segment2 === 'question' && Array.isArray(newParagraphArray)) {
          newParagraphArray = newParagraphArray[0];
        }
        newEditedData.content[segment1][segment1Index][segment2] = newParagraphArray;
      } else if (splitId.includes('requirements')) {
        newEditedData.content[segment1][segment1Index][segment2] = newParagraphArray;
      } else {
        newEditedData.content[segment1][segment1Index].slides[splitId[splitId.length - 1]].lowerText = newParagraphArray;
      }
    } else {
      // newText = newText.replace(/\r?\n|\r/g, "");
      if (splitId.length === 3) {
        newEditedData.content[segment1][segment1Index][segment2] = newText;
      } else if (splitId.length === 4) {
        newEditedData.content[segment1][segment1Index][segment2][segment2Index] = newText;
        if (segment2 === 'address') {
          console.warn('we doing an address');
          let addressArr = [...newEditedData.content[segment1][segment1Index][segment2]];
          addressArr[2] = addressArr[2].split(' ').reverse()[0];
          const addressString = addressArr.slice(1, 3).join(' ');
          mapProvider = mapProvider ? mapProvider : (mapProvider = new OpenStreetMapProvider());
          const results = await mapProvider.search({ query: addressString });
          console.info('res', results);
          let foundCoords;
          if (results.length && results[0].x) {
            console.warn('COORDS result good', results[0]);
            foundCoords = { x: results[0].x, y: results[0].y };
            setAddressOk(true);
          } else {
            setAddressOk(false);
          }
          console.log('bit to add to this', newEditedData.content[segment1][segment1Index][segment2]);
          newEditedData.content[segment1][segment1Index].addressCoords = foundCoords;
          console.log('COOORDS set to', foundCoords);
        }
      } else if (splitId.length === 5) {
        if (segment3.length === 1) {
          segment3 = parseInt(segment3);
        }
        newEditedData.content[segment1][segment1Index][segment2][segment2Index][segment3] = newText;
      } else if (splitId.length === 6) {
        newEditedData.content[segment1][segment1Index][segment2][segment2Index][segment3][segment3Index] = newText;
      }
    }
    updateLiveView(newEditedData, 'unsaved');
    setUserEditedData(newEditedData);
    lastChanged = Date.now();
  };
  const closeModal = () => {
    setModalShowing(undefined);
  };
  const getCopy = obj => {
    return JSON.parse(JSON.stringify(obj));
  };
  const handleChangePass = async (oldPass, newPass) => {
    let response = await changePassword(userData, oldPass, newPass);
    console.warn('change password responded', response.data);
    if (response.data === 'PASSWORD CHANGED') {
      setCookie('eggborne-cms', null, 0);
      setModalShowing(undefined);
      setPopupMessage('PASSWORD CHANGED!');
      setSaveMessageActive(true);
      await wait(1200);
      setSaveMessageActive(false);
    } else if (response.data === 'WRONG PASSWORD') {
      setInputStatus({ ...inputStatus, badPassword: true });
      setTimeout(() => {
        setInputStatus({ ...inputStatus, working: false, badPassword: false });
      }, 1600);
    }
  };
  const handleClickAdjustStyle = e => {
    let sectionIndex = parseInt(e.target.id.split('-')[2]);
    console.log('clicked ind', sectionIndex);
    setSelectedSection(sectionIndex);
    setModalShowing('adjustStyle');
  };
  const applyColorLimit = colorValue => {
    let darkLimit = parseInt(offBlack.substr(1, 2), 16);
    let lightLimit = parseInt(offWhite.substr(1, 2), 16);
    let newValue = colorValue;
    let rgb = {
      r: parseInt(colorValue.substr(1, 2), 16),
      g: parseInt(colorValue.substr(3, 2), 16),
      b: parseInt(colorValue.substr(5, 2), 16)
    };
    console.warn('rgb was', rgb);
    console.warn('limit', darkLimit);
    if (Object.values(rgb).every(val => val < darkLimit)) {
      console.warn('TOO DARK');
      newValue = offBlack;
    } else if (Object.values(rgb).every(val => val > lightLimit)) {
      console.warn('TOO LIGHT');
      newValue = offWhite;
    }
    if (newValue !== colorValue) {
      console.warn('LIMITED TO', newValue);
    }
    return newValue;
  };
  const changeStyle = (sectionIndex, attribute, newValue) => {
    setSaveStatus('unsaved');
    console.warn('-----> changeStyle got', sectionIndex, attribute, newValue);
    let newUserData = { ...userData };
    // if (attribute === 'fancyBorderColor' || attribute === 'iconColor' || attribute === 'backgroundColor' || attribute === 'headerColor' || attribute === 'color') {
    //   console.log(attribute, 'is limited!')
    //   newValue = applyColorLimit(newValue);
    // }
    if (attribute === 'blackLogo') {
      newUserData.globalStyles[attribute] = newValue;
    } else if (attribute === 'groomColor') {
      newUserData.content.sections[sectionIndex].slides[0].bgColor = newValue;
    } else if (attribute === 'bathColor') {
      newUserData.content.sections[sectionIndex].slides[1].bgColor = newValue;
    } else if (attribute === 'headerColor' || attribute === 'backgroundOpacity') {
      newUserData.globalStyles[attribute] = newValue;
    } else if (attribute === 'backgroundImageSize') {
      console.warn('current inmage is', userSelectedImages);
      newUserData.globalStyles[attribute] = newValue;
    } else if (attribute === 'stretchBackground') {
      console.error('setting', attribute, 'to', newValue);
      if (!newValue) {
      }
      newUserData.globalStyles[attribute] = newValue;
    } else if (attribute === 'fancyBorderColor') {
      let filters = getColorFilters(newValue).filter;
      filters = filters.substr(0, filters.length - 1);
      newUserData.content.sections[sectionIndex].style['fancyBorderFilters'] = filters;
      newUserData.content.sections[sectionIndex].style[attribute] = newValue;
    } else if (attribute === 'iconColor') {
      let filters = getColorFilters(newValue).filter;
      // filters = filters.substr(0, filters.length - 1);
      newUserData.content.sections[sectionIndex].style['iconFilters'] = filters;
      newUserData.content.sections[sectionIndex].style[attribute] = newValue;
    } else {
      newUserData.content.sections[sectionIndex].style[attribute] = newValue;
    }
    if (!editedSections.includes(sectionIndex)) {
      let newEdited = [...editedSections];
      newEdited.push(parseInt(sectionIndex));
      setEditedSections(newEdited);
    }
    updateLiveView(newUserData, 'unsaved');
    setUserEditedData(newUserData);
  };
  const restoreStyle = (sectionIndex, oldSectionData) => {
    let newUserData = { ...userData };
    newUserData.content.sections[sectionIndex] = oldSectionData;
    setUserEditedData(newUserData);
    setUserData(newUserData);
    updateLiveView(newUserData);
  };
  const addParagraph = (oldPara, newPara) => {
    console.log('oldPara', oldPara.classList);
    console.log('newPara', newPara.classList);
    if (newPara.classList.contains('hidden')) {
      console.warn('NEW WAS UNHIDDEN');
      newPara.classList.remove('hidden');
    }
    let splitOldId = oldPara.id.split('-');
    let splitNewId = newPara.id.split('-');
    console.log('splitOldId', splitOldId);
    console.log('splitNewId', splitNewId);
    console.log('got', splitOldId, splitNewId);
    let newUserData = { ...userData };
    if (splitNewId.includes('addendum')) {
      let existingSlotForOld = newUserData.content[splitOldId[0]][splitOldId[1]][splitOldId[2]][splitOldId[3]][splitOldId[4]][splitOldId[5]];
      if (existingSlotForOld) {
        console.warn('existingSlotForOld ABOUT -=-=-=-=-=-=-=-=-=-=-=-=-');
        newUserData.content[splitOldId[0]][splitOldId[1]][splitOldId[2]][splitOldId[3]][splitOldId[4]][splitOldId[5]] = oldPara.innerText;
      }
      let splicePos = parseInt(splitOldId[5]) + 1;
      console.log('splicing to', splicePos);
      newUserData.content[splitNewId[0]][splitNewId[1]][splitNewId[2]][splitNewId[3]][splitNewId[4]].splice(splicePos, 0, newPara.innerText);
    } else if (splitNewId.includes('faqs')) {
      console.log('faq addending!', splitOldId);
      let existingSlotForOld = newUserData.content[splitOldId[0]][splitOldId[1]][splitOldId[2]][splitOldId[3]];
      if (existingSlotForOld) {
        console.warn('existingSlotForOld FAQ -=-=-=-=-=-=-=-=-=-=-=-=-', oldPara.innerText);
        newUserData.content[splitOldId[0]][splitOldId[1]][splitOldId[2]][splitOldId[3]] = oldPara.innerText;
      }
      let splicePos = parseInt(splitOldId[3]) + 1;
      console.log('splicing to', splicePos, newPara.innerText);
      console.log('doing newUserData.content', splitNewId[0], splitNewId[1], splitNewId[2], splitNewId[3]);
      newUserData.content[splitNewId[0]][splitNewId[1]][splitNewId[2]].splice(splicePos, 0, newPara.innerText);
    }
    console.log('made some', newUserData);
    updateLiveView(newUserData, 'unsaved');
    setUserData(newUserData);
    setUserEditedData({ ...newUserData });
  };
  const handleChangeFaqOrder = newUserData => {
    setSaveStatus('unsaved');
    updateLiveView(newUserData, 'unsaved');
    setUserData(newUserData);
    setUserEditedData({ ...newUserData });
  };
  const handleSelectImageForUpload = async e => {
    let fileArray = e.target.files;
    let newSelectedImages = [...userSelectedImages];
    let targetFileName = 'title-bg-image';
    if (document.getElementById('target-file-name')) {
      console.log(document.getElementById('target-file-name').value);
    }
    [...fileArray].forEach(fileData => {
      newSelectedImages.push({
        url: URL.createObjectURL(fileData),
        fileName: fileData.name,
        targetFileName: targetFileName
      });
    });
    setUserSelectedImages(newSelectedImages);
    console.warn('newSelectedImages', newSelectedImages);
    let newUserData = { ...userData };
    let newFileName = fileArray[0].name;
    let newUrl = `https://cms.eggborne.com/${userData.siteName}/assets/images/${newFileName}`;
    newUserData.globalStyles.backgroundImageUrl = newUrl;
    uploadSelectedImages();
    // let updateResult = await updateUserCategory(userData.username, userData.siteName, userData.token, 'globalStyles', newUserData.globalStyles);
    // if (updateResult && updateResult.backgroundImageUrl) {
    updateLiveView(newUserData, 'unsaved');
    setUserData(newUserData);
    setUserEditedData({ ...newUserData });
    // console.log(liveWindow)
    // liveWindow.reload();
    // }

    // document.getElementById('background-image-frame').postMessage('suck my balls', '*')
    // document.getElementById('background-preview').style.backgroundImage = 'none';
  };
  const uploadSelectedImages = e => {
    // document.getElementById('background-image-form').action = 'https://cms.eggborne.com/php/cmsfileupload.php';
    document.getElementById('background-image-form').submit();
  };
  const toggleLiveView = e => {
    // setLiveViewOn(!liveViewOn);
    if (window.liveWindow) {
      window.liveWindow.close();
    }
    let type;
    if (e.target.id.indexOf('mobile') > -1) {
      type = 'mobile';
    } else {
      type = 'desktop';
    }
    let liveWidth;
    let liveHeight;
    let frameWidth;
    let frameHeight;
    if (type === 'mobile') {
      // liveHeight = window.innerHeight;
      // liveWidth = liveHeight * (347/720);
      liveWidth = 411;
      liveHeight = 823;
      frameWidth = '94.3%';
      frameHeight = '81%';
    } else {
      liveWidth = window.innerWidth * 0.9;
      liveHeight = window.innerHeight * 0.9;
      frameWidth = '100%';
      frameHeight = '100%';
    }
    if (!window.IS_LANDSCAPE) {
      frameWidth = '100%';
      frameHeight = '100%';
    }
    if (liveWindow && !liveWindow.closed) {
      console.log(liveWindow.document.body.offsetWidth);
      let wrongSize =
        (type === 'mobile' && liveWindow.document.body.offsetWidth >= liveWindow.document.body.offsetHeight) ||
        (type === 'desktop' && liveWindow.document.body.offsetHeight >= liveWindow.document.body.offsetWidth);
      if (wrongSize) {
        liveWindow.close();
        setLiveWindow(undefined);
      } else {
        liveWindow.focus();
        return;
      }
    }
    let live = window.open('', 'liveViewWindow', `alwaysOnTop=1,width=${liveWidth},height=${liveHeight},centerscreen=1,titlebar=0,location=0,personalbar=0,status=0`);
    live.document.body.style.margin = '0px';
    live.document.body.style.backgroundColor = userData.globalStyles.headerColor;
    live.document.body.innerHTML = `
      <img id='live-bg' src='${liveBgPhoneUrl}'/>
      <div id='live-container'>
        <iframe id='live-frame' src=${liveUrl} />
      </div>
    `;
    live.document.getElementById('live-container').style = `
      width: 100%;
      height: 100%;
    `;

    live.document.getElementById('live-frame').style = `
      position: absolute;
      width: ${frameWidth};
      height: ${frameHeight};
      top: ${(window.IS_LANDSCAPE && type === 'mobile') && '8.33%'};     
      left: ${(window.IS_LANDSCAPE && type === 'mobile') && '2.88%'};     
      border: 0;
    `;
    live.document.getElementById('live-bg').style = `
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;        
      width: 100%;
      z-index: 0;
      pointer-events: none;
      display: ${(!window.IS_LANDSCAPE || type !== 'mobile') && 'none'};
    `;
    live.document.title = `${type.toUpperCase()} live view`;
    live.document.title = `${quoteGenerator.getLine(10, 2, true)}`;
    setLiveWindow(live);
    window.liveWindow = live;
    let hadSaved = saveStatus;
    live.document.getElementById('live-frame').addEventListener('load', function() {
      requestAnimationFrame(() => {
        console.warn('LIVE IS LOADED!! saved?', hadSaved);
        // userData.username.indexOf('Guest-') === -1 && updateLiveView(userEditedData, hadSaved);
      });
    });
    console.error('SETTING LIVE WINDOW!!');
  };
  const toggleAutoSave = () => {
    let newStatus = !autoSave;
    console.warn('autoSave now will be', newStatus);
    setAutoSave(newStatus);
    if (newStatus) {
      saveInterval = setInterval(() => {
        let sinceChanged = Date.now() - lastChanged;
        console.log('lastChanged', lastChanged);
        console.log('sinceChanged', sinceChanged);
        if (lastChanged && sinceChanged > 1000 && sinceChanged < 2000) {
          console.log('SAVING!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
          handleClickSaveData(true);
          lastAutoSaved = Date.now();
        } else {
          console.warn('opted not to save becauase too soon since last keystroke');
        }
        console.warn('---- ---- ----');
      }, 1000);
    } else {
      clearInterval(saveInterval);
    }
  };

  const handleChangeFont = (type, family) => {
    let newUserData = { ...userEditedData };
    console.log('CHANGING', `${type}Font to`, family);
    // newUserData.fonts[`${type}Font`].fontFamily = family;
    newUserData.globalStyles[`${type}Font`].fontFamily = family;
    updateLiveView(newUserData, 'unsaved');
    setSaveStatus('unsaved');
    setUserEditedData(newUserData);
  };
  const handleChangeFontSize = (e, val, type) => {
    let newUserData = { ...userEditedData };
    console.log('CHANGING', `${type}Font size to`, val);
    newUserData.globalStyles[`${type}Font`].fontSize = val;
    updateLiveView(newUserData, 'unsaved');
    setSaveStatus('unsaved');
    setUserEditedData(newUserData);
  };

  const loadTheme = newThemeIndex => {
    const newThemeData = themes[newThemeIndex].contents;
    const newEditedData = { ...userEditedData };
    console.log('newTheme', newThemeData);
    newThemeData.sections.forEach((sec, s) => {
      if (s > 0) {
        newEditedData.content.sections[s].style = sec.style;
      }
    });
    newEditedData.globalStyles.titleFont = newThemeData.globalStyles.titleFont;
    newEditedData.globalStyles.mainFont = newThemeData.globalStyles.mainFont;
    // newEditedData.fonts = newThemeData.fonts;
    updateLiveView(newEditedData, 'unsaved');
    setUserData(newEditedData);
    setUserEditedData({ ...newEditedData });
    setSaveStatus('unsaved');
  };
  const updateBackgroundPreview = file => {
    console.warn('updatebackg got', file);
    let newUserData = { ...userEditedData };
    let tempUrl = URL.createObjectURL(file);
    let liveUrl = `https://cms.eggborne.com/${userData.siteName}/assets/images/${file.name}`;
    newUserData.globalStyles.backgroundImagePreviewUrl = liveUrl;
    updateLiveView(newUserData, 'unsaved');
    // document.getElementById('background-preview-image').src = tempUrl;
    document.getElementById('background-preview-image').style.backgroundImage = `url('${tempUrl}')`;
    document.getElementById('portrait-background-preview-image').style.backgroundImage = `url('${tempUrl}')`;
    setUserData(newUserData);
    setUserEditedData({ ...newUserData });
    updateUserCategory(userData.username, userData.siteName, userData.token, 'globalStyles', newUserData.globalStyles);
    setSaveStatus('unsaved');
    // setUserSelectedImages({
    //   url: liveUrl,
    //   fileName: fileData.name,
    //   targetFileName: targetFileName
    // });
  };
  const updateGalleryPreview = async file => {
    console.warn('updatebackg got', file);
    let newUserData = { ...userEditedData };
    let tempUrl = URL.createObjectURL(file);
    let liveUrl = `https://cms.eggborne.com/${userData.siteName}/assets/images/gallery/${file.name}`;
    let testImg = new Image();
    testImg.crossOrigin = 'Anonymous';
    testImg.src = liveUrl;
    document.body.appendChild(testImg);
    testImg.addEventListener('load', async () => {
      let crop = await getSquareCropForImage(testImg);
      console.log('newimageObj crop', crop);
      let newImageObj = {
        fileName: file.name,
        title: 'Image Title',
        description: `Gallery image: ${file.name}`,
        crop: {
          x: `${((crop.x / crop.width) * 100).toFixed(0)}%`,
          y: `${((crop.y / crop.height) * 100).toFixed(0)}%`,
          width: `${((crop.width / testImg.offsetWidth) * 100).toFixed(0)}%`,
          height: `${((crop.height / testImg.offsetHeight) * 100).toFixed(0)}%`
        },
        url: liveUrl
        // targetFileName: targetFileName
      };
      console.log('newImageObj izz', newImageObj);
      newUserData.images.gallery.push(newImageObj);
      updateLiveView(newUserData, 'unsaved');
      setUserData(newUserData);
      setUserEditedData({ ...newUserData });
      updateUserCategory(userData.username, userData.siteName, userData.token, 'images', newUserData.images);
    });
    document.body.remove(testImg);
    // document.getElementById('background-preview-image').src = tempUrl;
  };

  const handleClickDeleteGalleryImage = fileName => {
    let newUserData = { ...userEditedData };
    newUserData.images.gallery = newUserData.images.gallery.filter(image => image.fileName !== fileName);
    updateLiveView(newUserData, 'unsaved');
    setUserData(newUserData);
    setUserEditedData({ ...newUserData });
    updateUserCategory(userData.username, userData.siteName, userData.token, 'images', newUserData.images);
  };
  const toggleStretchBackground = () => {
    let newUserData = { ...userData };
    newUserData.globalStyles.stretchBackground = !userData.globalStyles.stretchBackground;
    setUserData(newUserData);
  };
  if (!originalData && userData.content) {
    console.warn('recording original');
    let data = { ...userData };
    data = getCopy(data);
    setOriginalData(data);
  }
  return props.isWindow ? (
    <div id='window-mode-container'></div>
  ) : (
    <AppBody isWindow={props.isWindow} className={loaded && 'landed'} autoSave={autoSave} opacity={userData.globalStyles ? (userData.globalStyles.backgroundOpacity * 100).toFixed(0) : 'N'}>
      {modalShowing === 'uploadImage' && <ImageUploadScreen userData={userData} closeModal={closeModal}></ImageUploadScreen>}
      <Header
        username={userData.username}
        siteName={userData.siteName}
        updatedTime={timeSinceUpdated()}
        loaded={loaded}
        userLoggedIn={userLoggedIn}
        onClickLoadTheme={handleClickLoadTheme}
        onClickSaveTheme={handleClickSaveTheme}
        onClickChangePassword={handleClickChangePassword}
        onClickChangeMode={handleClickChangeMode}
        quoteGenerator={quoteGenerator}
      />
      {userLoggedIn ? (
        <>
          {(testMode || userData.username.indexOf('Guest') === -1) && <marquee id='quote-banner'>{bannerLine}</marquee>}
          <div className='global-controls'>
            <div className='global-section'>
              <div className='floating-label-box' id='title-font-select'>
                {/* <div>Title</div> */}
                <FontChooser onChangeFont={handleChangeFont} type='title' currentFont={userData.globalStyles.titleFont.fontFamily} />
                <Slider
                  defaultValue={parseFloat(userData.globalStyles.titleFont.fontSize)}
                  aria-labelledby='discrete-slider'
                  valueLabelDisplay='auto'
                  step={0.02}
                  marks
                  min={0.5}
                  max={3.5}
                  onChange={(e, val) => handleChangeFontSize(e, val.toString(), 'title')}
                />
              </div>
            </div>
            <div className='global-section'>
              <div className='floating-label-box' id='main-font-select'>
                {/* <div>Main</div> */}
                <FontChooser onChangeFont={handleChangeFont} type='main' currentFont={userData.globalStyles.mainFont.fontFamily} />
                <Slider
                  defaultValue={parseFloat(userData.globalStyles.mainFont.fontSize)}
                  aria-labelledby='discrete-slider'
                  valueLabelDisplay='auto'
                  step={0.02}
                  marks
                  min={0.5}
                  max={2}
                  onChange={(e, val) => handleChangeFontSize(e, val.toString(), 'main')}
                />
              </div>
            </div>
            <div className='global-section'>
              <div className='floating-label-box' id='logo-color-select'>
                <div onClick={() => changeStyle(0, 'blackLogo', true)} className={`black radio-choice ${userData.globalStyles.blackLogo ? `selected` : ``}`}></div>
                <div onClick={() => changeStyle(0, 'blackLogo', false)} className={`white radio-choice ${userData.globalStyles.blackLogo ? `` : `selected`}`}></div>
              </div>
            </div>
            <div className='global-section'></div>
            <div className='background-controls'>
              <h4>Background Image</h4>
              <BackgroundPreview
                username={userData.username}
                token={userData.token}
                tint={userData.globalStyles.headerColor}
                opacity={userData.globalStyles.backgroundOpacity}
                backgroundImageUrl={userData.globalStyles.backgroundImageUrl}
                backgroundImageSize={userData.globalStyles.backgroundImageSize}
                updateBackgroundPreview={updateBackgroundPreview}
                stretchBackground={userData.globalStyles.stretchBackground}
              />
              <BackgroundPreview
                portrait={true}
                username={userData.username}
                token={userData.token}
                tint={userData.globalStyles.headerColor}
                opacity={userData.globalStyles.backgroundOpacity}
                backgroundImageUrl={userData.globalStyles.backgroundImageUrl}
                backgroundImageSize={userData.globalStyles.backgroundImageSize}
                updateBackgroundPreview={updateBackgroundPreview}
                stretchBackground={userData.globalStyles.stretchBackground}
              />
              <div className='half-row'>
                <div id='background-tint-select' className='floating-label-box'>
                  <input id='headerColor' type='color' value={userData.globalStyles.headerColor} onChange={e => changeStyle(0, 'headerColor', e.target.value)} />
                </div>
                <div id='background-stretch-select' className='floating-label-box'>
                  <Switch checked={userData.globalStyles.stretchBackground} onChange={(e, val) => changeStyle(e, 'stretchBackground', val)} value={'autoSave'} />
                </div>
              </div>
              <div className='background-size-control' id='background-size-slider-area'>
                <h4 style={{ marginRight: '1rem' }}>
                  Size{' '}
                  <span style={{ color: `#eed` }}>
                    {userData.globalStyles.backgroundImageSize === '100% 100%' || userData.globalStyles.stretchBackground ? 'Stretching' : userData.globalStyles.backgroundImageSize + '%'}
                  </span>
                </h4>
                <Slider
                  defaultValue={parseFloat(userData.globalStyles.backgroundImageSize)}
                  aria-labelledby='discrete-slider'
                  valueLabelDisplay='auto'
                  step={1}
                  min={50}
                  max={200}
                  marks={[{ value: 100 }]}
                  onChangeCommitted={(e, val) => changeStyle(e, 'backgroundImageSize', val)}
                  disabled={userData.globalStyles.stretchBackground}
                />
              </div>
              <div className='background-size-control' id='background-size-slider-area'>
                <h4 style={{ marginRight: '1rem' }}>
                  Opacity <span style={{ color: `#eed` }}>{Math.round(userData.globalStyles.backgroundOpacity * 100)}%</span>
                </h4>
                <Slider
                  defaultValue={parseFloat(userData.globalStyles.backgroundOpacity)}
                  aria-labelledby='discrete-slider'
                  step={0.01}
                  min={0}
                  max={1}
                  onChangeCommitted={(e, val) => changeStyle(e, 'backgroundOpacity', val)}
                />
              </div>
            </div>
            <div className='global-section'>
              <div id='title-legend-input' className='floating-label-box'>
                <p id={`sections-0-headline`} contentEditable onInput={handleChangeField}>
                  {userData.content.sections[0].headline}
                </p>
              </div>
            </div>
          </div>
          {userData && userData.images && (
            <GalleryDisplay
              type={'gallery'}
              username={userData.username}
              token={userData.token}
              imageList={userData.images.gallery}
              siteName={userData.siteName}
              updateGalleryPreview={updateGalleryPreview}
              onClickDeleteGalleryImage={handleClickDeleteGalleryImage}
            />
          )}
          <PanelArea>
            {userData.content &&
              userData.content.sections.map(
                (section, s) =>
                  s > 0 && (
                    <Panel
                      key={section.title}
                      edited={editedSections.includes(s)}
                      addParagraph={addParagraph}
                      userData={userData}
                      handleChangeField={handleChangeField}
                      handleClickAdjustStyle={handleClickAdjustStyle}
                      handleChangeFaqOrder={handleChangeFaqOrder}
                      handleChangeFont={handleChangeFont}
                      section={section}
                      addressOk={addressOk}
                      s={s}
                      changeStyle={changeStyle}
                    />
                  )
              )}
          </PanelArea>
          <footer>
            <div className='upper-tab-area'>
              <Button onClick={() => setFooterOptionSelected('live-view')} className={footerOptionSelected === 'live-view' ? 'selected' : ''}>Live View</Button>
              <Button onClick={() => setFooterOptionSelected('theme')} className={footerOptionSelected === 'theme' ? 'selected' : ''}>Themes</Button>
            </div>
            <div className={`footer-button-bar`}>
              <div className={`footer-button-group live-view-area${window.IS_LANDSCAPE || footerOptionSelected === 'live-view' ? '' : ' hidden'}`}>
                <div id='live-view-tab' className='footer-option-tab'>LIVE VIEW</div>
                <Button id='live-view-mobile-button' onClick={toggleLiveView}>
                  Mobile
                </Button>
                <Button disabled={!window.IS_LANDSCAPE} id='live-view-desktop-button' onClick={toggleLiveView}>
                  Desktop
                </Button>
              </div>
              <div className='footer-divider' style={{ width: '2px', height: 'calc(var(--footer-height) * 0.7)', background: '#aaaaaa44', margin: '0 0.5rem' }}></div>
              <div className={`footer-button-group theme-area${window.IS_LANDSCAPE || footerOptionSelected === 'theme' ? '' : ' hidden'}`}>
                <div id='theme-tab' className='footer-option-tab'>THEMES</div>
                <Button className='theme-button' onClick={handleClickLoadTheme}>
                  Load Theme
                </Button>
                <Button className='theme-button' onClick={handleClickSaveTheme}>
                  Save Theme
                </Button>
                {/* <div className='switch-label'>Auto Save</div> */}
                {/* <Switch checked={autoSave} onChange={toggleAutoSave} value={'autoSave'} /> */}
              </div>
            </div>
            {!footerMenuOpen && <SaveButton saveStatus={saveStatus} saveLegend={saveLegend} autoSave={autoSave} handleClickSaveData={handleClickSaveData} updatedTime={timeSinceUpdated()} />}
          </footer>
          {modalShowing === 'changePassword' && (
            <ChangePasswordModal
              showing={loaded}
              working={inputStatus.working}
              badPassword={inputStatus.badPassword}
              badUser={inputStatus.badUser}
              handleCancel={closeModal}
              changePassword={handleChangePass}
            />
          )}
          {modalShowing === 'adjustStyle' && (
            <AdjustStyleModal
              showing={modalShowing === 'adjustStyle'}
              working={inputStatus.working}
              sectionIndex={selectedSection}
              section={userData.content.sections[selectedSection]}
              changeStyle={changeStyle}
              restoreStyle={restoreStyle}
              onClickSaveData={handleClickSaveData}
              closeModal={closeModal}
            />
          )}
          {modalShowing === 'loadTheme' && (
            <LoadThemeModal
              showing={modalShowing === 'loadTheme'}
              themes={themes}
              working={inputStatus.working}
              sectionIndex={selectedSection}
              section={userData.content.sections[selectedSection]}
              loadTheme={loadTheme}
              closeModal={closeModal}
            />
          )}
        </>
      ) : (
        loaded &&
        !userData.token && (
          <>
            <LoginFields
              showing={loaded}
              working={inputStatus.working}
              badPassword={inputStatus.badPassword}
              badUser={inputStatus.badUser}
              onSubmitLogin={handleSubmitLogin}
              onClickLogInAsGuest={handleClickLogInAsGuest}
            />
          </>
        )
      )}
      <SaveIndicator active={popupMessageActive} message={popupMessage} />
    </AppBody>
  );
}

// const areEqual = (prevProps, nextProps) => {
//   console.log('check equal')
//   let equal = JSON.stringify(prevProps.userData.sections).length === JSON.stringify(nextProps.userData.sections).length;
//   console.log('app euqal?', equal)
//   return equal;
// }

// export default React.memo(App)
export default App;
