import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { useState, useEffect } from 'react';

let dropzoneTemplate = `
  <div class="dz-preview dz-file-preview">
    <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
  </div>
`;



const GalleryDisplayContainer = styled.div`
  align-self: center;
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
  padding: 0;
  width: calc(100% - 1rem);
  background-color: #aaa;

  & > h4 {
    font-size: 1.5rem;
    padding-top: 1rem;
  }

  @media (orientation: landscape) {
    width: min-content !important;
  }
`;
const GalleryImage = styled.div`
  position: relative;
  width: var(--gallery-image-height);
  height: var(--gallery-image-height);
  background-image: url('${props => props.url}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: ${props => props.crop.x};
  background-position-y: ${props => props.crop.y};

  & button:hover {
    background: #060;
  }
`;
const MoveButton = styled(Button)`
  &#gallery-move-button {
    position: absolute;
    padding: calc(var(--gallery-image-height) / 24);
    margin: 0;
    min-width: unset;
    height: calc(var(--gallery-image-height) / 6);
    bottom: calc(var(--gallery-image-height) / 24);
    left: calc(var(--gallery-image-height) / 24);
    box-shadow: 0 0 0.1rem #00000099;
  }
`;
const DeleteButton = styled(Button)`
  &#gallery-delete-button {
    padding: 0;
    margin: 0;
    position: absolute;
    border-radius: 50%;
    min-width: unset;
    width: calc(var(--gallery-image-height) / 6);
    height: calc(var(--gallery-image-height) / 6);
    background-color: #900;
    bottom: calc(var(--gallery-image-height) / 24);
    right: calc(var(--gallery-image-height) / 24);
  }
  &#gallery-delete-button:hover {
    background-color: #600;
  }
`;
const ImageList = styled.div`
  position: relative;
  display: grid !important;
  grid-template-columns: repeat(2, var(--gallery-image-height));
  grid-template-rows: auto;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  justify-content: center;
  justify-items: center;
  padding: 1rem !important;
  
  > .add-button {
    width: var(--gallery-image-height);
    height: var(--gallery-image-height);
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 0.3rem dotted green;
    cursor: pointer;
  }
  @media (orientation: landscape) {
    
    grid-template-columns: repeat(5, var(--gallery-image-height));
  }
`;
function GalleryDisplay(props) {
  useEffect(() => {
    const extraParams = {
      username: props.username,
      token: props.token,
      uploadType: 'gallery'
    };
    window.galleryImageDropzone = new window.Dropzone('div#add-button', {
      url: 'https://cms.eggborne.com/php/cmsfileupload2.php',
      paramName: 'gallery-image',
      params: extraParams,
      maxFilesize: 10, // MB
      headers: {
        'Cache-Control': null,
        'X-Requested-With': null
      },
      accept: (file, done) => {
        done();
      },
      previewTemplate: dropzoneTemplate
    });
    window.galleryImageDropzone.on('complete', function(file) {
      props.updateGalleryPreview(file);
      window.galleryImageDropzone.removeFile(file);
    });
  }, []);


  return (
    <GalleryDisplayContainer className='floating-label-box' id='gallery-display'>
      <h4>Gallery</h4>
      <ImageList>
        {props.imageList.map((image, i) => {
          return (
            <GalleryImage
              key={image.fileName}
              url={image.url}
              crop={image.crop}
            >
              <MoveButton
                id='gallery-move-button'
                variant='contained'
                >
                MOVE
              </MoveButton>
              <DeleteButton
                id='gallery-delete-button'
                variant='contained'
                onClick={() => props.onClickDeleteGalleryImage(image.fileName)}
                >
                X
              </DeleteButton>
            </GalleryImage>
          );
         })}
        <div id='add-button' className='add-button'>
          Add new
        </div>
      </ImageList>
    </GalleryDisplayContainer>
  );
}

// const areEqual = (prevProps, nextProps) => {
//   let equal = prevProps.imageList.length === nextProps.imageList.length;
//   return equal;
// }

// export default React.memo(GalleryDisplay, areEqual);
export default GalleryDisplay;
