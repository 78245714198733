import React, { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';

const StyleCard = styled(Card)`
  position: fixed;
  width: auto;
  max-width: 90vw;
  max-height: 90vh;
  transform: translate(-50%, 0);
  top: 5vh;
  left: 50%;
  padding: 1% 2rem;
  font-size: 0.8rem;
  font-weight: bold;
  display: flex !important;
  flex-direction: column !important;
  align-items: stretch !important;

  & header {
    font-size: 1.5rem;
    height: var(--footer-height);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & > .control-area {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: stretch;
    align-items: stretch;
    grid-column-gap: 0.5rem;
    grid-row-gap: 2vh;
  }
  & .radio-area {
    /* align-items: flex-end; */
  }
  & .double-input {
    display: flex;
    /* align-items: flex-end; */
  }

  & .input-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /* height: calc(var(--footer-height) * 1.5); */
  }
  .input-row > div:first-child {
    align-items: center;
    justify-content: space-evenly;
  }
  .input-row > div:first-child button {
    background: #770;
    height: 1.5rem;
    margin-bottom: 0.1rem;
  }
  & input[type=color] {
    width: 100%;
    height: 100%;
    padding: 0;
    border: 0;
    cursor: pointer;
    height: calc(var(--footer-height));
  }
  & .button-area {
    flex-basis: calc(var(--footer-height) * 2);
    flex-shrink: calc(var(--footer-height));
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    padding: calc(var(--footer-height) * 0.25) 0;
  }
  & .button-area button {
    height: calc(var(--footer-height) * 0.75);
    margin: unset !important;
    padding: unset;
    width: 100%;
  }
  & button:last-child {
    background: green;
    color: white;
  }
  @media screen and (orientation: landscape) {
    min-width: 30rem;
  }
`;


export default function AdjustStyleModal(props) {

  const [origSection, setOrigSection] = useState({});

  useEffect(() => {
    console.log('setting initial style!')
    setOrigSection(JSON.parse(JSON.stringify({...props.section})))
  }, [props.section]);

  const handleChangeColor = (e) => {
    let type = e.target.id;
    let newValue =  e.target.value;
    if (!type) {
      console.log('clicked MATCH button');
      type = 'fancyBorderColor';
      newValue = props.section.style.color;
    }
    console.log('type', type, 'has value', e.target.value);
    props.changeStyle(props.sectionIndex, type, newValue);    
  }
  const handleCancel =async () => {
    props.restoreStyle(props.sectionIndex, origSection)
    props.closeModal();
  }
	let cardClass = props.showing ? 'Card bouncing' : 'Card';

	return (
		<StyleCard id='adjust-style-card' className={cardClass}>
      <header>{props.section.title === 'intro' ? 'title page' : props.section.title}</header>
      <div className='control-area'>
      <div className='input-row'>
        <div>Background</div>
        <input 
          id='backgroundColor'
          type='color'
          value={props.section.style.backgroundColor}
          onChange={handleChangeColor}
        />
      </div>
      {props.sectionIndex > 0 &&
        <>
          <div className='input-row'>
            <div>Text</div>
            <input 
              id='color'
              type='color'
              value={props.section.style.color}
              onChange={handleChangeColor}
            />
          </div>          
          <div className='input-row'>
            <div style={{ display: `flex`}} >
              Border:
              <button style={{ fontSize: `0.7rem`, lineHeight: 0.75}} onClick={handleChangeColor}>Match text</button>
            </div>
            <input 
              id='fancyBorderColor'
              type='color'
              value={props.section.style.fancyBorderColor}
              onChange={handleChangeColor}
            />
          </div>
          {props.section.pricedServices &&
          // Services page
          <>
            <div className='double-input'>
              <div className='input-row'>
                <div>Groom</div>
                <input 
                  id='groomColor'
                  type='color'
                  value={props.section.slides[0].bgColor}
                  onChange={handleChangeColor}
                />
              </div>
              <div className='input-row'>
                <div>Bath</div>
                <input 
                  id='bathColor'
                  type='color'
                  value={props.section.slides[1].bgColor}
                  onChange={handleChangeColor}
                />
              </div>
            </div>
            <div className='input-row'>
              <div>Icons</div>
              <input 
                id='iconColor'
                type='color'
                value={props.section.style.iconColor || '#000000'}
                onChange={handleChangeColor}
              />
            </div>
            </>
          }              
        </>        
      }
    </div>
    <div className='button-area'>
      <Button className={`Button`}
        onClick={handleCancel}
        color='primary'
        variant='contained'>
        Cancel
      </Button>
      <Button className={`Button`}
        onClick={props.closeModal}
        variant='contained'>
        Done
      </Button>
    </div>
		</StyleCard>
	);
}
