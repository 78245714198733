import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import FontPicker from 'font-picker-react';

const FontChooserContainer = styled.div`

  @media screen and (orientation: landscape) {
    max-width: 40vw;
    & > * {
      max-width: 40vw;
    }
  }
`;

function FontChooser(props) {
	const [activeFontFamily, setActiveFontFamily] = useState(props.currentFont);
	console.log('activefontfam', activeFontFamily);
	useEffect(() => {
		setActiveFontFamily(props.currentFont);
	}, [props.currentFont])
	return (
		<FontChooserContainer id={`${props.type}-dropdown`} className='font-dropdown'>
			<FontPicker
				apiKey={'AIzaSyDl5jN4vU8Kk7mhR3kCdcfY-QULIlOdiuA'}
				activeFontFamily={activeFontFamily}
				pickerId={props.type}
				limit={200}
				categories={props.type === 'title' ? [`handwriting`, `display`] : []}
				onChange={nextFont => {
					setActiveFontFamily(nextFont.family);
					props.onChangeFont(props.type, nextFont.family);
				}}
			/>
		</FontChooserContainer>
	);
}
const areEqual = (prevProps, nextProps) => {
	return prevProps.currentFont === nextProps.currentFont;
}
export default React.memo(FontChooser, areEqual);
