import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';

const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  max-width: 100%;
  font-weight: bold;
  height: var(--header-height);
  color: var(--header-text-color);
  padding: 0 calc(var(--header-height) / 10);
  background: var(--header-color);
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  display: grid;
  grid-template-columns: minmax(min-content, max-content) 1fr auto;
  align-content: stretch;
  grid-column-gap: 1vw;
  /* display: flex; */
  z-index: 1;
  
  &.unlogged > *:not(.main-title) {
    display: none;
  }
  & > .main-title {
    position: absolute;
    top: 0;
    left: 0;
    background: inherit;
    padding: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    z-index: 2;
  }
  & > .central-area {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  & > .central-area > button:last-child {
    /* margin: 0 calc(var(--header-height) / 5); */
  }
  & > .user-display {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto;
    grid-column-gap: calc(var(--header-height) / 12);
    grid-row-gap: calc(var(--main-font-size) / 4);    
    align-items: center;
    align-content: center;
    justify-items: end;
    justify-content: end;
    font-size: 0.65rem;
    height: 100%;
    opacity: ${props => (props.userLoggedIn ? '1' : '0')};
    transition: opacity 300ms ease;
  }
  & > .user-display span {
    color: #ada
    justify-self: end;
  }
  & > .user-display span:first-of-type {
    font-size: calc(var(--header-height) / 5);
    align-self: end;
  }
  & .icon-button {
    padding: unset;
    min-width: unset;
    min-height: unset;
    width: calc(var(--header-height) / 1.5);
    height: calc(var(--header-height) / 1.5);
    color: white;
    background: green;
    margin-right: 0;
    grid-row-end: span 2;
  }
  & .icon-button i {
    font-size: calc(var(--header-height) / 1.65);
  }
  & .icon-button:hover {
    background: green;
  }
  & span > i.small {
    font-size: calc(var(--header-height) / 5);
    margin-right: calc(var(--header-height) / 12);
    align-self: center;
  }
  & span:last-child {
    max-height: 1rem;
    color: var(--header-text-color);
    /* grid-column-end: span 2; */
    display: flex;
    align-self: end;
  }
  @media (orientation: landscape) {
    & > .main-title {
      position: unset;
      padding: unset;  
      display: flex;
      align-items: center;
      justify-content: space-evenly;
    }
  }
`;

const BarButton = styled(Button)`
  &.bar-button {
    /* width: 100%; */
    flex-basis: calc(var(--header-height) * 3);
    max-width: calc(var(--header-height) * 3);
    /* flex-grow: 0.35; */
    /* flex-basis: 15vw; */
    font-weight: normal
    font-size: 4vw;
    max-height: unset;
    height: 100%;
    border-radius: 0;
    background: transparent;
    border-left: 2px solid #aaaaaa77
    border-right: 2px solid #aaaaaa77

    &:first-child {
      
    }
  }

  @media (orientation: landscape) {    
    &.bar-button {
    font-size: calc(var(--header-height) / 3);
    }
  }
`;

function Header(props) {
  console.log('header props', props)
  const [timeDisplay, setTimeDisplay] = useState(undefined);
  let titleLegend = props.userLoggedIn ? 
    `${props.siteName.toUpperCase()[0] + props.siteName.substr(1, props.siteName.length - 1)} CMS` : 'Fancy CMS';
  console.log('tiotkleleg', titleLegend)
  useEffect(() => {
    if (props.updatedTime) {
      setTimeDisplay(props.updatedTime);
    }
  }, [props.updatedTime]);
  
	return (
    <HeaderContainer userLoggedIn={props.userLoggedIn}>
      <div className='main-title'>
        <div>{titleLegend}</div>
        {/*  */}
      </div>
        <div className='central-area'>
          {/* <BarButton className='bar-button' onClick={() => props.onClickChangeMode('style')}>STYLE </BarButton>
          <BarButton className='bar-button' onClick={() => props.onClickChangeMode('content')}> CONTENT </BarButton> */}
        </div>
      <div className='user-display'>
        <span>{props.username}</span>
        <Button className='Button icon-button' onClick={props.onClickChangePassword} variant='contained'>
          <i className='material-icons'>account_box</i>
        </Button>
        <span>
          <i className='material-icons small'>alarm</i>
          {timeDisplay}
        </span>
      </div>
    </HeaderContainer>
  );
}

const areEqual = (prevProps, nextProps) => {
  let equal = prevProps.updatedTime === nextProps.updatedTime 
  && prevProps.userLoggedIn === nextProps.userLoggedIn
  ;
  return equal;
}
export default React.memo(Header, areEqual);
