import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import Card from '@material-ui/core/Card';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';

const InputModal = styled(Card)`
	position: fixed;
	max-width: min-content;
	/* max-width: 90vw; */
  opacity: 0;
  transform: translate(-50%, 0);
  top: calc(var(--footer-height) / 2);
	left: 50%;
	padding: calc(var(--footer-height) / 4) calc(var(--footer-height) / 8);
	
	& > #login-form {
		width: min-content;
		display: flex !important;
		flex-direction: column !important;
		align-items: center;
		/* justify-content: space-between; */
	}
	& .button-row {
		height: var(--footer-height);
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		align-self: stretch;
		padding: calc(var(--footer-height) / 6) 0;
	}
	& .button-row > button {
		margin: calc(var(--footer-height) / 6) 0;
		height: 100%;
	}
`;

export default function ChangePasswordModal(props) {

	const [values, setValues] = useState({
		oldPassword: '',
    newPassword: '',
    newPasswordAgain: '',
		showPassword: false,
		showOldPassword: false,
		showNewPassword: false,
  });
  const [errorStatus, setErrorStatus] = useState({
    noMatch: false,
    fieldsTooShort: true
  });

	const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
    let comparator;
    let remainder;
    if (name === 'newPassword') {
      comparator = values.newPasswordAgain;
      remainder = values.oldPassword;
    } else if (name === 'newPasswordAgain') {
      comparator = values.newPassword;
      remainder = values.oldPassword;    
    } else if (name === 'oldPassword') {
      comparator = values.newPassword;
      remainder = values.newPasswordAgain;
    }
    let newErrorStatus = {...errorStatus};
    let noPassMatch;
    if (name === 'newPassword' || name === 'newPasswordAgain') {
      noPassMatch = event.target.value !== comparator;
      if (noPassMatch !== newErrorStatus.noMatch) {
        newErrorStatus.noMatch = noPassMatch;
      }
    }
    let tooShort = event.target.value.length < 6 || comparator.length < 6 || remainder.length < 6;
    if (tooShort !== newErrorStatus.tooShort) {
      newErrorStatus.fieldsTooShort = tooShort;
    }
    setErrorStatus(newErrorStatus);
	};
	const handleClickShowPassword = (name) => {
    let attrName = `show${name[0].toUpperCase()}${name.slice(1)}`
    console.log('flipping', attrName)
    setValues({ ...values, [attrName]: !values[attrName] });
	};

	const handleMouseDownPassword = event => {
		event.preventDefault();
	};
	const handleChangePassword = () => {
    console.log('passing', values)
    if (values.newPassword === values.newPasswordAgain) {
      props.changePassword(values.oldPassword, values.newPassword)
    } else {
      console.warn('no match ?>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')      
    }
	}
  let cardClass = props.showing ? 'Card bouncing' : 'Card';
  
  let oldPassClass = values.oldPassword.length >= 6 ? `TextArea ready` : `TextArea`;
  let newPassClass = values.newPassword.length >= 6 && !errorStatus.noMatch ? `TextArea ready` : `TextArea`;
  let newPassAgainClass = values.newPasswordAgain.length >= 6 && !errorStatus.noMatch ? `TextArea repeat-password ready` : `TextArea repeat-password`;

  if (props.badPassword) {
    oldPassClass += ` error`
  }
  if (errorStatus.noMatch) {
    newPassAgainClass += ` error`
  }

	return (
		<InputModal id='change-password-card' className={cardClass}>
			<header>Change password</header>
			<form id='login-form' noValidate>
				<TextField
					shrink={'true'}
					id='password-field'
					label={'Old Password'}
					error={props.badPassword}
					type={values.showOldPassword || props.badPassword ? 'text' : 'password'}
					autoComplete='current-password'
					className={oldPassClass}
					margin='normal'
					variant='outlined'
					onChange={handleChange('oldPassword')}
					value={props.badPassword ? 'INVALID PASSWORD' : values.password}
					InputProps={{
						endAdornment: (
							<InputAdornment position='end'>
								<IconButton
									edge='end'
									aria-label='toggle password visibility'
									onClick={() => handleClickShowPassword('oldPassword')}
									onMouseDown={handleMouseDownPassword}
								>
									{values.showOldPassword ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
				<TextField
					shrink={'true'}
					id='new-password-field'
          label={'New Password'}
          error={false}
					type={values.showNewPassword ? 'text' : 'password'}
					className={newPassClass}
					margin='normal'
					variant='outlined'
					onChange={handleChange('newPassword')}
					value={values.newPassword}
					InputProps={{
						endAdornment: (
							<InputAdornment position='end'>
								<IconButton
									edge='end'
									aria-label='toggle password visibility'
									onClick={() => handleClickShowPassword('newPassword')}
									onMouseDown={handleMouseDownPassword}
								>
									{values.showNewPassword ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
				<TextField
					shrink={'true'}
					id='repeat-new-password-field'
					label={'Repeat New Password'}
					error={errorStatus.noMatch}
					type={values.showNewPassword ? 'text' : 'password'}
					className={newPassAgainClass}
					margin='normal'
					variant='outlined'
					onChange={handleChange('newPasswordAgain')}
          value={values.newPasswordAgain}
					InputProps={{
						endAdornment: (
							<InputAdornment position='end'>
								<IconButton
									edge='end'
									aria-label='toggle password visibility'
									onClick={() => handleClickShowPassword('newPassword')}
									onMouseDown={handleMouseDownPassword}
								>
									{values.showNewPassword ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
        <div className='button-row'>
				<Button className={'Button'}
					onClick={props.handleCancel}
					color='primary'
					variant='contained'>
					Cancel
      	</Button>
        <Button className={'Button'}
          style={{background: `green`, color: `white`}}
					onClick={handleChangePassword}
					// color='primary'
					variant='contained'
          disabled={errorStatus.noMatch || errorStatus.fieldsTooShort}>
					Change
      	</Button>
        </div>
			</form>
		</InputModal>
	);
}
