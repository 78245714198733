import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import Card from '@material-ui/core/Card';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';

export default function LoginFields(props) {

	const [values, setValues] = useState({
		name: '',
		password: '',
		showPassword: false,
	});

	const handleChange = name => event => {
		setValues({ ...values, [name]: event.target.value });
	};
	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};

	const handleMouseDownPassword = event => {
		event.preventDefault();
	};
	const handleLogin = (e) => {
		e.preventDefault();
		props.onSubmitLogin(values.name, values.password)
	}
	let cardClass = props.showing ? 'Card bouncing' : 'Card';

	return (
    <Card id='login-card' className={cardClass}>
      <form id='login-form' onSubmit={handleLogin} noValidate>
        <TextField
          shrink={'true'}
          id='name-field'
          label='Name'
          error={props.badUser}
          className={props.badUser ? 'TextArea error' : 'TextArea'}
          margin='normal'
          variant='outlined'
          value={props.badUser ? 'NO SUCH USER' : values.name}
          onChange={handleChange('name')}
        />
        <TextField
          shrink={'true'}
          id='password-field'
          label={'Password'}
          error={props.badPassword}
          type={values.showPassword || props.badPassword ? 'text' : 'password'}
          autoComplete='current-password'
          className={props.badPassword ? 'TextArea error' : 'TextArea'}
          margin='normal'
          variant='outlined'
          onChange={handleChange('password')}
          value={props.badPassword ? 'INVALID PASSWORD' : values.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton edge='end' aria-label='toggle password visibility' onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <Button className={!values.name.length || !values.password.length || props.working ? 'Button disabled' : 'Button'} type='submit' color='primary' variant='contained'>
          Sign In
        </Button>
      </form>
      <Button variant='contained' className='guest-button' onClick={props.onClickLogInAsGuest}>
        Sign in as Guest
      </Button>
    </Card>
  );
}
