import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';

const SaveButtonContainer = styled(Button)`
  &#save-button {
    width: 8rem;
    height: calc(var(--footer-height));
    color: white;
    font-size: 1.25rem;   
    text-transform: none;
    transform-origin: right;
    transition: all 300ms ease;
    background: var(--mustard-yellow);
    margin: 0;
  }

  &:not(.unsaved):hover {
    opacity: 0.75;
  }
  &.unsaved:not(.saving):hover {
    transform: scale(1.05);
    background: rgb(9, 172, 9) !important;
  }

  &#save-button.saving.auto-save::before {    
    position: absolute;
    content: 'AUTO SAVING';
    top: 0;
    transform: translateY(-100%);
    left: 0;
    height: min-content;
    width: inherit;
    text-align: center;
    color: green;
    font-size: 0.65rem;
  }
  
  &#save-button.auto-save {
    padding-bottom: 1.25rem !important;
    border-radius: 0 !important;
    pointer-events: none;
    outline: 2px solid green;
  }
  &#save-button.auto-save.unsaved {
    outline-color: #ffa;
    color: #ffffffaa;
  }
  &#save-button.saved, &#save-button.untouched {
    padding-bottom: 1.25rem;
    background: var(--mustard-yellow);
    border-radius: 0;
  }
  &#save-button:not(.auto-save).saving {
    animation-name: flash-button;
    animation-timing-function: ease;
    animation-duration: 400ms;
    animation-iteration-count: infinite;
    animation-play-state: running;
  }
  &#save-button:not(.auto-save).unsaved {
    background: #005500;
    border: 0.2rem outset green;
  }
  &#save-button:not(.auto-save):not(:hover).unsaved {
    animation-name: bounce-button;
    animation-timing-function: ease;
    animation-duration: 800ms;
    animation-fill-mode: both;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-play-state: running;
    animation-delay: 600ms;
    
  }
  &#save-button::after {
    position: absolute;
    content: '';
    top: calc(var(--footer-height) - 1.75rem);
    left: 0;
    height: var(--footer-height);
    width: inherit;
    text-align: center;
    color: white;
    font-size: 0.75rem;
    opacity: 0;
    transform: translateY(0.2rem);
    transition: opacity 300ms ease, transform 300ms ease;
    transition-delay: 300ms;
    z-index: 4;    
  }
  &#save-button:not(.auto-save).unsaved::after {
    content: '${props => props.saveLegend}';
    transition-delay: 0ms;
    transition: none;
    opacity: 0;
  }
  &#save-button.auto-save::after {
    content: '${props => props.saveLegend}';
    opacity: 1;
    transform: none;
  }
  &#save-button:not(.auto-save).saved::after {
    content: '${props => props.saveLegend}';
    opacity: 1;
    transform: none;
  }
`;

function SaveButton(props) {
  return (
    <SaveButtonContainer
      id='save-button'
      className={`${props.saveStatus}${props.autoSave ? ' auto-save' : ''}`}
      onClick={props.handleClickSaveData}
      saveLegend={props.saveLegend}
      autoSave={props.autoSave}
    >
      {
      (!props.autoSave && props.saveStatus === 'saving') ? 'SAVING' : 
      (!props.autoSave && props.saveStatus === 'unsaved') ? 'SAVE' :
      'SAVED'
    }
    </SaveButtonContainer>
  );
}

export default SaveButton;
