import React, { useState, useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Grow from '@material-ui/core/Grow';
import styled from 'styled-components';

function GrowTransition(props) {
  return <Grow {...props} />;
}

const SaveContainer = styled.div`
  position: fixed;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 60vmin;
  display: flex;
  justify-content: center;
  z-index: 1;
  & > * {
    align-self: center;
  }
`;

const Toast = styled(Snackbar)`
  display: flex;  
`;

function SaveIndicator(props) {

	const [state, setState] = React.useState({
    open: true,
    Transition: Grow,
  });

  const handleClose = () => {
    console.warn('closing?')
    setState({
      ...state,
      open: false,
    });
  };

  useEffect(() => {
    if (props.active && !state.open) {
      console.warn('opening?')
      setState({
        open: true,
        GrowTransition,
      });
    } else if (!props.active && state.open) {
      handleClose();
    }
  }, [props.active, state.open]);

	return (
		<SaveContainer>
      
      <Toast
        open={state.open}
        onClose={handleClose}
        TransitionComponent={state.Transition}
        ContentProps={{
          'aria-describedby': 'save-message',
        }}
        message={<span id="save-message">{props.message}</span>}
      />
    </SaveContainer>
	);
}

 export default SaveIndicator;
