import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';


const SectionCard = styled(Card)`
  border: 2px solid rgba(0, 0, 0, 0.312) !important;
  background-color: #ccc !important;
  transition: background 300ms ease !important;

  &.edited {
    background-color: #cdc !important;
  }
  &.edited::after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    text-align: center;
    content: 'EDITED';
    font-weight: bold;
    font-size: 0.8rem;
    padding: 0.5rem;
    color: #00990077;
  }
  &.intro {
    background: transparent !important;
    box-shadow: unset !important;
    border: unset !important;
    padding: 1rem 1rem !important;
  }
  & header {
    align-self: stretch;
    height: calc(var(--header-height) * 1.5);
  }
  & button {
    font-size: 0.75rem;
    margin-left: 2.5vmin;
    min-height: calc(var(--header-height) / 1.5);
  }
  & button.arrange-button {
    width: calc(var(--header-height) * 1.5);
    background-color: var(--mustard-yellow);;
    color: white;
  }
  &.arranging {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    /* padding: 0 calc(var(--header-height) / 1.75); */
  }
  &.arranging button.arrange-button {
    background-color: rgb(82, 149, 6);
  }
  & .question br {
    display: none;
  }

  & span:not(:first-of-type) {
    display: block !important;
    padding-top: 1rem;
  }

  & .intro-controls {
    /* display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto calc(var(--header-height) * 1.5);
    grid-column-gap: var(--paragraph-spacing); */
    font-size: 1rem;
    font-weight: bold;
    padding: unset !important;
  }

  & .intro-controls input {
    width: 90%;
    height: calc(var(--header-height) * 1.25);
    grid-row-start: 2;
  }
  & .input-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-column-gap: var(--paragraph-spacing);
    justify-items: center;
  }
  & .field-row span {
    font-size: 1.2rem;
    font-weight: bold;
    padding: 0;
    margin: 0;
    width: 100%;
  }
  & .field-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    min-height: calc(var(--header-height) * 1.5) !important;
  }
  & .input-row > div {
    /* height: 1.5rem; */
  }
  & .field-row p {
    font-size: 1.2rem !important;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: unset;
    border-radius: calc(var(--header-height) / 16);
    border: 1px solid #00000088;
    padding: 1rem 0;
    margin-left: 0;
    margin-right: 0;
    /* padding-left: 0.5rem !important; */
    /* padding-right: 0.5rem !important; */
    /* margin-left: 0rem !important; */
    /* margin-right: 0rem !important; */
    /* margin-top: 0.25rem !important; */
  }
  & .radio-choice {
    /* justify-self: start; */
    height: calc(var(--header-height) * 1.25);
  }

  & .contact-grid {
    font-size: 1rem;
    width: 100%;
    display: grid;
    grid-template-columns: 0.5fr 0.5fr;
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 0.5rem;
    align-items: center;
  }
  & .contact-grid::after {
    grid-column-start: 2;
    /* position: absolute; */
    /* bottom: 0; */
    width: 100%;
    color: green;
    content: 'VALID';
  }
  & .contact-grid.invalid-address::after {
    color: red;
    content: 'INVALID';
  }
  & .address-rows {
    display: flex;
    flex-direction: column;
  }
  & .contact-grid > .contact-label {
    font-size: 0.8rem;
    font-weight: bold;
    text-align: right;
  }

  & .boxed-note-area {
    margin: 1rem;
    line-height: 75%;
    padding: 1rem !important;
    background: #00000022;
  }
  & #sections-0-headline {

  }

  @media screen and (orientation: landscape) {
    padding-left: calc(var(--header-height) / 1.75) !important;
    padding-right: calc(var(--header-height) / 1.75) !important;
  }
`;

function Panel(props) {

  const [arranging, setArranging] = useState(undefined);
  const [selected, setSelected] = useState(undefined);
  const panelEl = useRef(undefined);
  
  const handleChangeColor = (e) => {
    let type = e.target.id;
    let newValue =  e.target.value
    console.log('type', type, 'has value', e.target.value);
    props.changeStyle(props.s, type, newValue)
  }
  
  const handleClickArrangeFaqs = (e) => {
    if (arranging === 'faqs') {
      setArranging(undefined);
    } else {
      setArranging('faqs');
    }
  }
  const moveQuestion = (e) => {
    console.log('pressed', e.target.id);
    let qIndex = parseInt(e.target.id.split('-')[2]);
    let partnerIndex;
    let direction = e.target.id.split('-')[0];
    
    let newData = {...userData};
    console.log('before splicing newFaq is', newData.content.faqs);
    let toMove = newData.content.faqs.splice(qIndex, 1)[0];
    console.log('we mean to move', toMove);
    console.log('after splicing newFaq is', newData.content.faqs);
    
    if (direction === 'down') {
      partnerIndex = qIndex + 1;
      newData.content.faqs.splice(qIndex + 1, 0, toMove);
      console.log('after moving down newFaq is', newData.content.faqs);
    } else if (direction === 'up') {
      partnerIndex = qIndex - 1;
      newData.content.faqs.splice(qIndex - 1, 0, toMove);
      console.log('after moving up newFaq is', newData.content.faqs);
    }

    props.handleChangeFaqOrder(newData);
  };

  const addFaq = () => {
    let newData = {...userData};
    newData.content.faqs.push({
      question: `New Question`,
      answer: ['balls']
    });
    props.handleChangeFaqOrder(newData);
  }

  const changeLogoColor = (newColor) => {
    console.warn('clicked', newColor)
    props.changeStyle(props.s, 'blackLogo', newColor === 'BLACK')
  };

  let section = props.section;
  let handleChangeField = props.handleChangeField;
  let s = props.s;
  let userData = props.userData;

  let cardClass = 'section-card';
  cardClass += arranging === 'faqs' ? ' arranging' : '';
  cardClass += !s ? ' intro' : '';
  cardClass += props.edited ? ' edited' : '';

	return (
    <SectionCard ref={panelEl} className={cardClass}>
      {s > 0 && (
        <header>
          <div contentEditable onInput={handleChangeField} id={`sections-${s}-title`}>
            {section.title}
          </div>
          {s === 3 && (
            <Button className={`Button arrange-button`} id={`adjust-button-${s}`} onClick={handleClickArrangeFaqs} variant='contained'>
              {arranging === `faqs` ? `Done arranging` : `Add / Arrange`}
            </Button>
          )}
          {s > 0 && (
            <Button className={`Button`} id={`adjust-button-${s}`} onClick={props.handleClickAdjustStyle} color='primary' variant='contained'>
              Adjust colors
            </Button>
          )}
        </header>
      )}
      {s === 0 && (
        <span key='intro-area'>
          <div className={`intro-controls`}>
            <div className='input-row'>
              {/* <div>Header Tint</div>
              <input id='headerColor' type='color' value={userData.globalStyles.headerColor} onChange={handleChangeColor} /> */}
              {/* <div>Logo Color</div>
              <div className='radio-area'>
                <div onClick={() => changeLogoColor('BLACK')} className={`black radio-choice ${props.userData.globalStyles.blackLogo ? `selected` : ``}`}></div>
                <div onClick={() => changeLogoColor('WHITE')} className={`white radio-choice ${props.userData.globalStyles.blackLogo ? `` : `selected`}`}></div>
              </div> */}
            </div>
            <div className='input-row'></div>
          </div>
          <div className='field-row'>
            <span>Legend:</span>
            <p id={`sections-${s}-headline`} contentEditable onInput={handleChangeField} key={section.headline}>
              {section.headline}
            </p>
          </div>
        </span>
      )}
      {s === 1 && (
        <span key={`section-${s}-slide-area`}>
          {section.slides.map((slide, d) => (
            <span key={`section-${s}-slide-${d}`}>
              <div className='slide-headline' id={`sections-${s}-slides-${d}-headline`} contentEditable onInput={handleChangeField} key={`sections-${s}-slides-${d}-headline`}>
                {slide.headline}
              </div>
              <div className={`multi-paragraph`} id={`sections-${s}-service-description-${d}`} contentEditable onInput={handleChangeField}>
                {slide.lowerText.map((paragraph, p) => (
                  <p className='paragraph' id={`service-${d}-paragraph-${p}`} key={`sections-${s}-service-${d}-paragraph-${p}`}>
                    {paragraph}
                  </p>
                ))}
              </div>
            </span>
          ))}
          <div className={'service-area'}>
            {section.pricedServices.map((service, v) => (
              <div className='service-row' key={`service-row-${v}`}>
                <p id={`sections-${s}-pricedServices-${v}-name`} contentEditable onInput={handleChangeField}>
                  {service.name}
                </p>
                <p id={`sections-${s}-pricedServices-${v}-price`} contentEditable onInput={handleChangeField}>
                  {service.price}
                </p>
              </div>
            ))}
          </div>
          <div className='boxed-note-area'>
            <div id={`sections-${s}-note`} contentEditable onInput={handleChangeField} className='boxed-note'>
              {section.note}
            </div>
          </div>
        </span>
      )}
      {s === 2 &&
        section.slides.map((slide, d) => (
          <div className={`multi-paragraph`} id={`sections-${s}-slides-${d}`} contentEditable onInput={handleChangeField} key={`sections-${s}-slides-${d}`}>
            {slide.lowerText.map((paragraph, p) => (
              <p className='paragraph' id={`sections-${s}-slides-${d}-lowerText-${p}`} onInput={handleChangeField} key={`sections-${s}-slide-${d}-paragraph-${p}`}>
                {paragraph}
              </p>
            ))}
          </div>
        ))}
      {s === 3 &&
        userData.content.faqs.map((questionSet, q, arr) => (
          <span className='faq-row' key={`faq-set-${q}`}>
            <div className={`faqs-set${selected === questionSet.question ? ' selected' : ''}`} key={`question-${q}`}>
              <p className={`question`} id={`faqs-${q}-question`} contentEditable={arranging !== 'faqs'} onInput={arranging !== 'faqs' ? handleChangeField : ``}>
                {questionSet.question}
              </p>
              {arranging !== 'faqs' && (
                <div className={`multi-paragraph`} id={`faqs-${q}-answer`} contentEditable onInput={handleChangeField} key={`faqs-${q}-answer`}>
                  {questionSet.answer.map((paragraph, p) => (
                    <p className='paragraph' id={`faqs-${q}-answer-${p}`} key={`faqs-${q}-answer-${p}`}>
                      {paragraph}
                    </p>
                  ))}
                </div>
              )}
            </div>
            {arranging === 'faqs' && (
              <>
                <Button className={`Button`} id={`up-button-${q}`} onClick={moveQuestion} color='primary' disabled={q === 0} variant='contained'>
                  Up
                </Button>
                <Button className={`Button`} id={`down-button-${q}`} onClick={moveQuestion} color='primary' disabled={q === arr.length - 1} variant='contained'>
                  Down
                </Button>
              </>
            )}
          </span>
        ))}
      {props.section.type === 'req' && (
        <>
          <div className='boxed-note-area'>
            <div id={`sections-${s}-legend`} contentEditable onInput={handleChangeField} className='boxed-note'>
              {section.legend}
            </div>
          </div>
          {userData.content.requirements.map((reqSet, q, arr) => (
            <span className='faq-row' key={`req-set-${q}`}>
              <div className={`faqs-set${selected === reqSet.headline ? ' selected' : ''}`} key={`headline-${q}`}>
                <p className={`question`} id={`reqs-${q}-question`} contentEditable={arranging !== 'reqs'} onInput={arranging !== 'reqs' ? handleChangeField : ``}>
                  {reqSet.headline}
                </p>
                {arranging !== 'reqs' && (
                  <div className={`multi-paragraph`} id={`requirements-${q}-bodyText`} contentEditable onInput={handleChangeField} key={`reqs-${q}-bodyText`}>
                    {reqSet.bodyText.map((bodyMember, p) =>
                      typeof bodyMember === 'string' ? (
                        <p className='paragraph' id={`reqs-${q}-bodyText-${p}`} key={`requirements-${q}-bodyText-${p}`}>
                          {bodyMember}
                        </p>
                      ) : (
                        <span className='paragraph complex' id={`reqs-${q}-bodyText-${p}`} key={`reqs-${q}-bodyText-${p}`}>
                          <p contentEditable={false} className='subheadline'>
                            {bodyMember.subheadline}
                          </p>
                          {bodyMember.subtext.map((subPara, s) => (
                            <p className='paragraph' id={`reqs-${q}-bodyText-${p}-${s}`} key={`reqs-${q}-bodyText-${p}-${s}`}>
                              {subPara}
                            </p>
                          ))}
                        </span>
                      )
                    )}
                  </div>
                )}
              </div>
              {arranging === 'reqs' && (
                <>
                  <Button className={`Button`} id={`up-button-${q}`} onClick={moveQuestion} color='primary' disabled={q === 0} variant='contained'>
                    Up
                  </Button>
                  <Button className={`Button`} id={`down-button-${q}`} onClick={moveQuestion} color='primary' disabled={q === arr.length - 1} variant='contained'>
                    Down
                  </Button>
                </>
              )}
            </span>
          ))}
        </>
      )}
      {props.section.phone && (
        <div className={props.addressOk ? 'contact-grid' : 'contact-grid invalid-address'}>
          <div className='contact-label'>Phone:</div>
          <div contentEditable className='phone-number' id={`sections-${s}-phone`} onInput={handleChangeField}>
            {props.section.phone}
          </div>
          <div className='contact-label'>Email:</div>
          <div contentEditable className='email' id={`sections-${s}-email`} onInput={handleChangeField}>
            {props.section.email}
          </div>
          <div className='contact-label'>Address:</div>
          <div className='address-rows'>
            {props.section.address.map((line, i) => (
              <div contentEditable className='address-row' id={`sections-${s}-address-${i}`} key={`sections-${s}-address-${i}`} onInput={handleChangeField}>
                {line}
              </div>
            ))}
          </div>
        </div>
      )}
      {arranging && (
        <Button className={`Button`} style={{ padding: `0`, margin: `1rem` }} onClick={addFaq} color='primary' variant='contained'>
          Add new
        </Button>
      )}
    </SectionCard>
  );
}

export default Panel;
